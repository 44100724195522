import { Box, Typography, useTheme, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNotifications } from 'src/contexts/NotificationContext';

export function UserProgress() {
  const { dependentsUpdate } = useNotifications();
  const theme = useTheme();

  //const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      {dependentsUpdate && (
        <Typography
          variant="subtitle1"
          color={theme.palette.grey[800]}
          sx={{ marginTop: '2.25rem', marginBottom: '1.25rem' }}
        >
          Agora que você já atualizou seu cadastro, continue seguindo os próximos passos.
        </Typography>
      )}
      <Card>
        {/* <Box sx={{ p: '1.25rem' }}>
          <Typography variant="button" color={theme.palette.warning.dark}>
            75 % completo
          </Typography>
          <Box sx={{ width: '100%', marginTop: '1rem', position: 'relative' }}>
            <ButtonBase
              aria-describedby={id}
              onClick={handleClick}
              // variant="contained"
              // color="warning"
              sx={{
                width: '40px',
                height: '40px',
                position: 'absolute',
                backgroundColor: '#FFF7CD',
                top: '-20px',
                right: 0,
                zIndex: 99,
                borderRadius: '9999999px',
              }}
            >
              <Iconify icon="bi:check-all" color="#FFC107" width={24} height={24} />
            </ButtonBase>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ fontSize: '14px' }}>Lista de verificação</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox defaultChecked />
                  <Typography sx={{ fontSize: '14px' }}>Atualização de cadastro</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox defaultChecked />
                  <Typography sx={{ fontSize: '14px' }}>Atualização de telefone</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox defaultChecked />
                  <Typography sx={{ fontSize: '14px' }}>Atualização de cadastro</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox defaultChecked />
                  <Typography sx={{ fontSize: '14px' }}>Atualização de cadastro</Typography>
                </Box>
              </Box>
            </Popover>
            <LinearProgress
              variant="determinate"
              value={50}
              sx={{
                backgroundColor: '#FFF7CD',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#FFC107',
                },
              }}
            />
          </Box>
        </Box> */}
        {/* <Divider /> */}
        {dependentsUpdate && (
          <Box
            sx={{ p: '1.25rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <Typography variant="subtitle2" color={theme.palette.grey[600]}>
              Se faz necessário que você atualize os dados dos seus dependentes para que eles também
              possam acessar o portal.
            </Typography>

            <Box
              sx={{
                marginTop: '1.25rem',
                backgroundColor: theme.palette.grey[200],
                padding: '0.8rem',
                borderRadius: '1rem',
                border: '1px solid #919EAB3D',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    background: '#00A1B114',
                    borderRadius: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/assets/icons/ic_authenticator.svg" alt="icon" />
                </Box>
                <Link
                  to="/dashboard/user/dependents"
                  style={{
                    textDecoration: 'none',
                    padding: '0.5rem 0.8rem',
                    backgroundColor: theme.palette.grey[600],
                    fontWeight: 700,
                    fontSize: '13px',
                    lineHeight: '22px',
                    color: '#FFFF',
                    borderRadius: '0.5rem',
                  }}
                >
                  Atualize seus dependentes
                </Link>
              </Box>
              <Typography
                sx={{ fontSize: '12px', lineHeight: '18px', marginTop: '1rem' }}
                color={theme.palette.grey[800]}
              >
                Se faz necessário que você atualize os dados dos seus dependentes para que eles
                também possam acesssar o portal.
              </Typography>
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
}

