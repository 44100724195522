import { Box, CircularProgress, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import { IhandleNotificationClick, NotificationItem } from 'src/components/NotificationItem';
import Page from 'src/components/Page';
import { useNotifications } from 'src/contexts/NotificationContext';
import useTabs from 'src/hooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';

export function Notifications() {
  const navigate = useNavigate();
  const { notifications, markAsRead, removeNotification, loadingNotifications } =
    useNotifications();
  const { currentTab, onChangeTab } = useTabs('Todas');

  const ACCOUNT_TABS = [
    {
      value: 'Todas',
      icon: <Iconify icon={'ic:outline-chat'} width={20} height={20} />,
    },
    {
      value: 'Não Lidas',
      icon: <Iconify icon={'ic:outline-mark-unread-chat-alt'} width={20} height={20} />,
    },
  ];

  const handleNotificationClick = (props: IhandleNotificationClick) => {
    const { viewed, batch, title } = props;

    if (title) {
      if (title.includes('dependente')) {
        navigate('/dashboard/user/dependents');
      } else if (title.includes('pagamento')) {
        navigate('/dashboard/historic');
      }
    }
    if (!viewed) {
      markAsRead(batch);
    }
  };

  const renderNotificationsByType = (
    type: 'providencia' | 'mensagem',
    filterViewed: boolean = false
  ) => {
    const filteredNotifications = notifications.filter((notification) => {
      const isTypeMatch = notification.type === type;
      const isViewedMatch = filterViewed ? !notification.opened : true;
      return isTypeMatch && isViewedMatch;
    });

    if (filteredNotifications.length === 0) return null;

    return (
      <>
        <Typography variant="subtitle1" sx={{ mt: 3, mb: 2 }}>
          {type.charAt(0).toUpperCase() + type.slice(1)} ({filteredNotifications.length})
        </Typography>
        {filteredNotifications.map((notification) => (
          <NotificationItem
            noWrap={false}
            key={notification.id}
            batch={notification.batch}
            title={notification.title}
            description={notification.body}
            timestamp={notification.createdAt}
            viewed={notification.opened}
            type={notification.type}
            onRemove={removeNotification}
            handleNotificationClick={handleNotificationClick}
          />
        ))}
      </>
    );
  };

  return (
    <Page title="Notificações">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
          heading="Notificações"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Notificações' }]}
        />
        <Divider />

        <Tabs value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Divider />

        <Box sx={{ pt: 3 }}>
          {loadingNotifications ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />

              <Typography variant="subtitle1" sx={{ textAlign: 'center', my: 3 }}>
                Buscando notificações...
              </Typography>
            </Box>
          ) : (
            <>
              {currentTab === 'Todas' && (
                <>
                  {renderNotificationsByType('providencia')}
                  {renderNotificationsByType('mensagem')}
                </>
              )}

              {currentTab === 'Não Lidas' && (
                <>
                  {renderNotificationsByType('providencia', true)}
                  {renderNotificationsByType('mensagem', true)}
                </>
              )}

              {notifications.length === 0 && (
                <Typography variant="subtitle1" sx={{ textAlign: 'center', my: 3 }}>
                  Não há novas notificações.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
}

