import { createContext, ReactNode, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import axiosInstance from 'src/utils/axios';

interface LecuponProviderProps {
  children: ReactNode;
}
interface lecuponContextData {
  getLecupon: () => void;
  linkLecupon: string;
  loadingLecupon: boolean;
}

export const LecuponContext = createContext({} as lecuponContextData);

export function LecuponProvider({ children }: LecuponProviderProps) {

  const { user } = useAuth();
  const [loadingLecupon, setLoadingLecupon] = useState(false);
  const [linkLecupon, setlinkLecupon] = useState('');


  const getLecupon = async () => {
    setLoadingLecupon(true);
    try {
      const response = await axiosInstance.get(`/users/lecupon/getlecupon/${user.cpf}`);

      setlinkLecupon(response.data.web_smart_link)

    } catch (error) {
      console.log("Error na busca lecupon: ", error);
    }
    setLoadingLecupon(false);
  }

  return (
    <LecuponContext.Provider
      value={{
        linkLecupon,
        getLecupon,
        loadingLecupon
      }}
    >
      {children}
    </LecuponContext.Provider>
  );
}
