import Iconify from 'src/components/Iconify';
import { Card, CardContent, CardMedia, Button, Typography } from '@mui/material';

interface CardNewsProps {
  id?: string;
  image: string;
  title: string;
  description: string;
  link: string;
}

export default function CardNews({ image, title, description, link, id }: CardNewsProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Card sx={{ width: '250px', height: '380px', margin: '10px', position: 'relative' }}>
        <CardMedia component="img" height="140px" image={image} alt="green iguana" />
        <CardContent sx={{ padding: '20px' }}>
          <Typography variant="subtitle2" fontWeight={600} font-fontSize="12px" textAlign="left">
            {title}
          </Typography>
          <Typography
            variant="body2"
            fontWeight={400}
            font-fontSize="10px"
            color="text.secondary"
            textAlign="left"
            justifyContent="center"
          >
            {description.slice(0, 99) + '...'}
          </Typography>
        </CardContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            target="_blank"
            href={`${link}`}
            size="small"
            sx={{
              fontSize: '13px',
              fontWeight: '700',
              position: 'absolute',
              bottom: '20px',
            }}
          >
            Saiba mais
            <Iconify icon={'bi:chevron-right'} marginLeft="10px" />
          </Button>
        </div>
      </Card>
    </div>
  );
}

