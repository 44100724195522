import { alpha } from '@mui/material/styles';
import {
  Link,
  Stack,
  Button,
  Tooltip,
  IconButton,
  ButtonProps,
  IconButtonProps,
} from '@mui/material';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export type SocialLinks = {
  instagram?: string;
  linkedin?: string;
  vila?: string;
};

type IProps = IconButtonProps & ButtonProps;

interface Props extends IProps {
  simple?: boolean;
  initialColor?: boolean;
  links?: SocialLinks;
}

export default function SocialsButton({
  initialColor = false,
  simple = true,
  links = {
    instagram: 'https://www.instagram.com/planosempre/?igshid=YmMyMTA2M2Y%3D',
    vila: 'https://empresavila.com.br/',
  },
  sx,
  ...other
}: Props) {
  const SOCIALS = [
    {
      name: 'Instagram',
      icon: 'iconoir:instagram',
      socialColor: '#E02D69',
      image: null,
      path: links.instagram || '#instagram-link',
    },
    {
      name: 'Empresa Vila',
      icon: '',
      image: <img src="/assets/logo.png" alt="entre em contato" style={{ height: 20 }} />,
      socialColor: '#020a0c',
      path: links.vila || '#vila-link',
    },
  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map((social) => {
        const { name, icon, path, socialColor } = social;
        return simple ? (
          <Link key={name} href={path} target="_blank">
            <Tooltip title={name} placement="top">
              <IconButton
                color="inherit"
                sx={{
                  ...(initialColor && {
                    color: socialColor,
                    '&:hover': {
                      bgcolor: alpha(socialColor, 0.08),
                    },
                  }),
                  ...sx,
                }}
                {...other}
              >
                {social.image ? (
                  social.image
                ) : (
                  <Iconify icon={icon} sx={{ width: 20, height: 20 }} />
                )}
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Button
            key={name}
            href={path}
            color="inherit"
            variant="outlined"
            size="small"
            startIcon={<Iconify icon={icon} />}
            sx={{
              m: 0.5,
              flexShrink: 0,
              ...(initialColor && {
                color: socialColor,
                borderColor: socialColor,
                '&:hover': {
                  borderColor: socialColor,
                  bgcolor: alpha(socialColor, 0.08),
                },
              }),
              ...sx,
            }}
            {...other}
          >
            {name}
          </Button>
        );
      })}
    </Stack>
  );
}
