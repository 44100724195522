import { useTheme } from '@mui/material/styles';
import { TiWarning } from 'react-icons/ti';

export function PasswordRequirement() {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: theme.palette.warning.lighter,
        color: theme.palette.warning.darker,
        borderRadius: '10px',
        padding: '15px 0',
        margin: '10px 0',
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'left',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', padding: '0 5px' }}>
        <TiWarning size={'25px'} color={theme.palette.warning.main} />
      </div>
      <div>
        Sua senha deve ter no mínimo 8 caracteres,e conter pelo menos:
        <ul style={{ listStyle: 'none' }}>
          <li>1 letra maiúscula;</li>
          <li>1 letra minúscula;</li>
          <li>1 número;</li>
          <li>1 caractere especial (Exemplo: @#$%^&+!=?*-/).</li>
        </ul>
      </div>
    </div>
  );
}
