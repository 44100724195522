import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Box,
  TextField,
  Typography,
  useTheme,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField } from '../../../components/hook-form';
import styled from '@emotion/styled';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import axiosInstance from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { PasswordRequirement } from '../reset-password/PasswordRequirement';
import { toast } from 'react-toastify';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { regexCamp } from './UpdateInitialForm';
import { statesBR } from 'src/@types/statesBR';
import { maritalStatusList } from 'src/@types/maritalStatus';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  cpf: string;
  email: string;
  contact: string;
  zipCode: string;
  address: string;
  house_number: string;
  neighborhood: string;
  complement: string;
  reference_point: string;
  marital_status: string;
  city: string;
  state: string;
  maritalStatus: string;
  password: string;
  confirmPassword: string;
  agreeTerms: boolean;
  newsletter: boolean;
  afterSubmit?: string;
};

export interface CepResponse {
  bairro: string;
  ibge: string;
  localidade: string; // CIDADE
  logradouro: string; // RUA
  uf: string; //UF
  erro?: boolean;
}
const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

export interface UpdateFormProps {
  handleOpenPrivateModal: () => void;
  handleOpenPrivacyModal: () => void;
}

export interface getCityProps {
  city: string;
  id: number;
}

let listCity: getCityProps[] = [];

export default function UpdateForm({
  handleOpenPrivateModal,
  handleOpenPrivacyModal,
}: UpdateFormProps) {
  const { login } = useAuth();
  const theme = useTheme();
  const [listCityByState, setListCityByState] = useState<getCityProps[]>([]);

  const [showPassword, setShowPassword] = useState(false);

  const { currentUser } = useUpdateSteps();

  const regexPassword =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm;

  const UpdateForm = Yup.object().shape({
    name: Yup.string().required('O nome completo').nullable(),
    cpf: Yup.string()
      .required('O CPF é obrigatório')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    email: Yup.string()
      .email('digite um E-mail valido')
      .required('Digite o e-mail')
      .nullable()
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
    contact: Yup.string()
      .required('Digite o número de contato')
      .test(
        'Digite o número de celular corretamente!',
        'Digite o número de celular corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    zipCode: Yup.string()
      .required('Digite o CEP')
      .test(
        'Digite o CEP corretamente!',
        'Digite o CEP corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    address: Yup.string()
      .required('Digite o Endereço')
      .nullable()
      .max(40, 'O endereço deve ter no máximo 40 caracteres'),
    house_number: Yup.string()
      .required('Numero da residência é obrigatório')
      .nullable()
      .max(15, 'O número da residência deve ter no máximo 15 caracteres'),
    neighborhood: Yup.string()
      .required('bairro é obrigatório')
      .nullable()
      .max(30, 'O bairro deve ter no máximo 30'),
    complement: Yup.string().nullable().max(50, 'O complemento deve ter no máximo 50'),
    reference_point: Yup.string()
      .nullable()
      .max(70, 'O ponto de referência deve ter no máximo 70 caracteres'),
    city: Yup.string().required('Cidade é obrigatório').nullable(),
    state: Yup.string().required('Estado é obrigatório'),
    maritalStatus: Yup.string().required('O Estado Civil é Obrigatório').nullable(),
    password: Yup.string()
      .required('Digite uma senha!')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),
    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
    agreeTerms: Yup.boolean()
      .oneOf([true], 'Você deve aceitar os Termos e Condições.')
      .required('Você deve aceitar os Termos e Condições.'),
    newsletter: Yup.boolean(),
  });
  const defaultValues = {
    name: currentUser.name,
    cpf: currentUser.cpf,
    email: currentUser.email,
    contact: currentUser.phone_number,
    zipCode: currentUser.zip_code,
    address: currentUser.adress,
    house_number: currentUser.house_number,
    neighborhood: currentUser.neighborhood,
    complement: currentUser.complement,
    reference_point: currentUser.reference_point,
    maritalStatus: currentUser.marital_status,
    state: currentUser.state,
    city: currentUser.city,
    agreeTerms: currentUser.use_terms,
    newsletter: currentUser.newsletter,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateForm),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;
  async function getCity(value: string) {
    setListCityByState([]);
    listCity = [];
    setValue('state', value);
    let { data } = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    );

    // eslint-disable-next-line array-callback-return
    await data.map((i: any) => {
      let resCity = {
        id: i.id,
        city: i.nome,
      };

      listCity.push(resCity);
    });

    setListCityByState(listCity);
  }

  const agreeTermsCheckbox = watch('agreeTerms');

  async function getLocationForCep() {
    const cep = getValues('zipCode').replace(/[^\d]+/g, '');

    const checkCEPEnd = cep.slice(4, 7);

    if (checkCEPEnd !== '000') {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

        if (data.erro === true) {
          toast.error('Não foi possível localizar o endereço pelo CEP informado.', {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }

        const { localidade, logradouro, uf, bairro } = data;

        setValue('address', logradouro);
        setValue('neighborhood', bairro);
        setValue('city', localidade);
        setValue('state', uf);

        getCity(getValues('state'));
      } catch (err) {
        toast.error('Não foi possível localizar o endereço pelo CEP informado', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });

        setValue('address', '');
        setValue('neighborhood', '');
        setValue('city', '');
        setValue('state', '');
      }
    }
  }
  // async function getLocationForCepInitial() {
  //   const cep = getValues('zipCode').replace(/[^\d]+/g, '');
  //   try {
  //     const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  //     const { localidade, uf } = data;
  //     setValue('city', localidade);
  //     setValue('state', uf);
  //     getCity(getValues('state'));
  //   } catch (err) {
  //     setValue('state', currentUser.state);
  //     setValue('city', currentUser.city);
  //   }
  // }

  async function renderCity() {
    getCity(getValues('state'));
  }
  const onSubmit = async (data: FormValuesProps) => {
    const cityUser = await listCityByState.find((i: any) => i.city === data.city);
    try {
      await axiosInstance.patch(`/users/${currentUser.id}`, {
        name: data.name,
        phone_number: data.contact.replace(/[^0-9]/g, ''),
        email: data.email,
        password: data.password,
        zip_code: data.zipCode.replace(/[^0-9]/g, ''),
        adress: data.address,
        neighborhood: data.neighborhood,
        complement: data.complement,
        reference_point: data.reference_point,
        cod_city: cityUser?.id.toString().substr(2),
        house_number: data.house_number,
        city: data.city,
        state: data.state,
        marital_status: data.maritalStatus,
        updated_at: new Date(),
      });
      try {
        await login(data.cpf, data.password);
        window.location.href = PATH_DASHBOARD.general.app;
      } catch (error) {
        toast.error('Erro na tentiva de realizar login!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });

        setTimeout(() => {
          window.location.href = PATH_AUTH.login;
        }, 4000);
      }
    } catch (error) {
      toast.error('Erro na atualização dos dados!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    getLocationForCep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Nome Completo
          </Typography>
          <RHFTextField name="name" size="small" disabled />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            CPF
          </Typography>
          <RHFTextField name="cpf" size="small" disabled />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            E-mail
          </Typography>
          <RHFTextField name="email" type="email" size="small" />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Contato
          </Typography>
          <Controller
            name="contact"
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask mask="(99)9 9999-9999" value={value} onChange={onChange}>
                <TextField
                  name="contact"
                  type="text"
                  size="small"
                  fullWidth
                  error={Boolean(errors.contact)}
                  helperText={error?.message}
                />
              </InputMask>
            )}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            CEP
          </Typography>
          <Box sx={{ display: 'flex', gap: '2rem' }}>
            <Controller
              name="zipCode"
              control={methods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <InputMask mask="99999-999" value={value} onChange={onChange}>
                  <TextField
                    sx={{ flex: 1 }}
                    name="zipCode"
                    type="text"
                    size="small"
                    fullWidth
                    error={Boolean(errors.zipCode)}
                    helperText={error?.message}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        getLocationForCep();
                      }
                    }}
                  />
                </InputMask>
              )}
            />
            <Box style={{ width: '30%' }}>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%', height: '100%' }}
                onClick={getLocationForCep}
                size="small"
              >
                Buscar meu CEP
              </LoadingButton>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: '2rem' }}>
          <Box style={{ flex: 1 }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Endereço
            </Typography>
            <RHFTextField name="address" size="small" />
          </Box>
          <Box style={{ width: '30%' }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Número
            </Typography>
            <RHFTextField name="house_number" type="text" size="small" />
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Bairro
          </Typography>
          <RHFTextField name="neighborhood" type="text" size="small" />
        </Box>
        <Box sx={{ display: 'flex', gap: '2rem' }}>
          <Box style={{ flex: 1 }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Complemento
            </Typography>
            <RHFTextField name="complement" size="small" />
          </Box>
          <Box style={{ width: '50%' }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Ponto de referência
            </Typography>
            <RHFTextField name="reference_point" type="text" size="small" />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '2rem' }}>
          <Box sx={{ width: '30%' }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Estado
            </Typography>
            <RHFSelect
              defaultValue="0"
              name="state"
              size="small"
              onChange={(e) => getCity(e.target.value)}
              onClick={() => renderCity()}
            >
              <option value="0" selected>
                Selecione
              </option>
              {statesBR.map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </RHFSelect>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              Cidade
            </Typography>
            <RHFSelect
              defaultValue="0"
              size="small"
              name="city"
              disabled={listCityByState.length === 0 && true}
            >
              <option value="0" selected hidden>
                Selecione
              </option>
              {listCityByState.map((i) => (
                <option key={i.id} value={i.city}>
                  {i.city}
                </option>
              ))}
            </RHFSelect>
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Estado Civil
          </Typography>
          <RHFSelect defaultValue="0" name="maritalStatus" size="small">
            <option value="0" disabled selected hidden>
              Selecione
            </option>
            {maritalStatusList.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </RHFSelect>
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Senha
          </Typography>

          <RHFTextField
            name="password"
            size="small"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Confirmar Senha
          </Typography>
          <RHFTextField
            name="confirmPassword"
            size="small"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <PasswordRequirement />
        <RHFCheckbox
          name="agreeTerms"
          label={
            <Box>
              Confirmo que li e estou de acordo com os{' '}
              <Link
                fontWeight="bolder"
                href="#handleOpenPrivateModal"
                onClick={handleOpenPrivateModal}
              >
                Termos de Uso
              </Link>{' '}
              e{' '}
              <Link
                fontWeight="bolder"
                href="#handleOpenPrivacyModal"
                onClick={handleOpenPrivacyModal}
              >
                Políticas de Privacidade.
              </Link>
            </Box>
          }
        />
        <RHFCheckbox
          name="newsletter"
          label="Desejo assinar a Newsletter e receber notícias e ofertas através de e-mail, SMS e Whastapp."
        />
      </Stack>
      <BoxButtonUpdate>
        <LoadingButton
          fullWidth
          disabled={!agreeTermsCheckbox}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Finalizar
          <MdOutlineKeyboardArrowRight />
        </LoadingButton>
      </BoxButtonUpdate>
    </FormProvider>
  );
}
