import { Dialog, DialogContent, IconButton, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';

interface PrivacyModalProps {
  onCloseModal: () => void;
  open: boolean;
}

export function PrivacyModal({ onCloseModal, open }: PrivacyModalProps) {
  return (
    <Dialog onClose={onCloseModal} open={open}>
      <DialogContent
        sx={{
          padding: '35px 16px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          onClick={onCloseModal}
        >
          <Iconify icon="ep:close" />
        </IconButton>
        <div role="document">
          <div style={{ marginBottom: '1rem' }}>
            <h1>Política de Privacidade</h1>
          </div>

          <section>
            <strong>A EMPRESA VILA</strong>{' '}
            <span>é uma marca, com mais de 7 (sete) décadas de existência,&nbsp;</span>
            <span>
              inspirada na trajetória de um sobrenome que é sinônimo de tradição, credibilidade,{' '}
            </span>
            <span>inovação, respeito aos clientes, parceiros e colaboradores.</span>
            <span>Ancorados nos valores de bem servir, a </span>
            <b>EMPRESA VILA </b>
            <span>reafirma que seguirá&nbsp;</span>
            <span>atuando com a mesma qualidade, excelência e comprometimento de sempre, </span>
            <span>mantendo-se como referência no mercado funerário e cemiterial brasileiro.</span>
            <span>
              Esta Política de Privacidade tem como objetivo informar, quais dados poderão ser&nbsp;
            </span>
            <span>coletadas pela </span>
            <b>EMPRESA VILA</b>
            <span>, quando da utilização dos seus canais digitais:&nbsp;</span>
            <a href="https://empresavila.com.br/">
              <span>https://empresavila.com.br/</span>
            </a>
            <span>, hotsites e processos que deste sistema fazem parte, bem&nbsp;</span>
            <span>como a forma pela qual tais informações são utilizadas e tratadas.</span>
            <b>DEFINIÇÕES</b>
            <p />
            <p>
              <span>
                Para fins da Lei Geral de Proteção de Dados – LGPD, nº 13.709/18,
                considera-se:&nbsp;
              </span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <b>Usuário/Cliente</b>
                <span>: pessoa que faz uso e/ou interage com algum dos canais digitais da </span>
                <b>EMPRESA VILA;</b>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Dado pessoal</b>
                <span>
                  : informação relacionada a pessoa natural identificada ou identificável;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Titular</b>
                <span>: pessoa natural a quem se referem os dados pessoais que são objeto de </span>
                <span>tratamento;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Controlador</b>
                <span>
                  : pessoa natural ou jurídica, de direito público ou privado, a quem&nbsp;
                </span>
                <span>competem as decisões referentes ao tratamento de dados pessoais;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Operador</b>
                <span>
                  : pessoa natural ou jurídica, de direito público ou privado, que realiza o&nbsp;
                </span>
                <span>tratamento de dados pessoais em nome do controlador;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Encarregado (DPO – </b>
                <b>
                  <i>Data Protection Officer</i>
                </b>
                <b>)</b>
                <span>: pessoa indicada pelo controlador e&nbsp;</span>
                <span>
                  operador para atuar como canal de comunicação entre o controlador, os titulares
                  dos{' '}
                </span>
                <span>dados e a Autoridade Nacional de Proteção de Dados (ANPD);</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Agentes de tratamento</b>
                <span>: o controlador e o operador; </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Tratamento</b>
                <span>
                  : toda operação realizada com dados pessoais, como as que se referem a&nbsp;
                </span>
                <span>
                  coleta, produção, recepção, classificação, utilização, acesso, reprodução,&nbsp;
                </span>
                <span>
                  transmissão, distribuição, processamento, arquivamento, armazenamento,&nbsp;
                </span>
                <span>
                  eliminação, avaliação ou controle da informação, modificação, comunicação,&nbsp;
                </span>
                <span>transferência, difusão ou extração;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Consentimento</b>
                <span>: manifestação livre, informada e inequívoca pela qual o titular&nbsp;</span>
                <span>
                  concorda com o tratamento de seus dados pessoais para uma finalidade determinada;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Bloqueio</b>
                <span>
                  : suspensão temporária de qualquer operação de tratamento, mediante&nbsp;
                </span>
                <span>guarda do dado pessoal ou do banco de dados;&nbsp;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Eliminação</b>
                <span>
                  : exclusão de dado ou de conjunto de dados armazenados em banco de&nbsp;
                </span>
                <span>dados, independentemente do procedimento empregado;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Transferência internacional de dados</b>
                <span>: transferência de dados pessoais para país&nbsp;</span>
                <span>estrangeiro ou organismo internacional do qual o país seja membro;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Uso compartilhado de dados</b>
                <span>: comunicação, difusão, transferência internacional,&nbsp;</span>
                <span>
                  interconexão de dados pessoais ou tratamento compartilhado de bancos de
                  dados&nbsp;
                </span>
                <span>
                  pessoais por órgãos e entidades públicos no cumprimento de suas competências&nbsp;
                </span>
                <span>
                  legais, ou entre esses e entes privados, reciprocamente, com autorização
                  específica,&nbsp;
                </span>
                <span>
                  para uma ou mais modalidades de tratamento permitidas por esses entes públicos,
                  ou&nbsp;
                </span>
                <span>entre entes privados;&nbsp;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Autoridade Nacional de Proteção de Dados (ANPD)</b>
                <span>: órgão da administração&nbsp;</span>
                <span>
                  pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei
                  em&nbsp;
                </span>
                <span>todo o território nacional;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <b>Cookies</b>
                <span>: pequenos arquivos criados por sites visitados e armazenados no&nbsp;</span>
                <span>
                  computador do Usuário/Cliente através do navegador, podendo ser usados para&nbsp;
                </span>
                <span>
                  identificar os visitantes, personalizar a página com base no perfil de
                  navegabilidade,&nbsp;
                </span>
                <span>
                  rastrear os padrões de uso para aprimoramento e controle, além de facilitar
                  o&nbsp;
                </span>
                <span>transporte de dados entre as páginas do mesmo site.</span>
              </li>
            </ol>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>DIREITOS DO TITULAR DOS DADOS</b>
            </p>
            <p>
              <span>A </span>
              <b>EMPRESA VILA </b>
              <span>disponibiliza acesso aos dados pessoais dos Usuários/Clientes</span>
              <span>,</span>
              <span>&nbsp;</span>
              <span>
                direito previsto na Lei Geral de Proteção de Dados – LGPD, nº 13.709/18, por meio
                do&nbsp;
              </span>
              <span>
                seu canal de comunicação com o Encarregado (DPO), para proporcionar aos seus&nbsp;
              </span>
              <span>
                Titulares acesso seguro e idôneo, facilitando sua obtenção (observado o
                segredo&nbsp;
              </span>
              <span>
                comercial, com base nos normativos de segurança da informação e de proteção de&nbsp;
              </span>
              <span>dados), de forma tempestiva e mediante requisição para:</span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <span>Confirmação da existência do processamento (Arts. 18(I), 19, LGPD); </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Acesso aos dados (Arts. 18(II), 19, LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Correção de dados incompletos, imprecisos ou desatualizados (Art. 18(III), LGPD)
                </span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Anonimização, bloqueio ou exclusão de dados ou dados desnecessários ou&nbsp;
                </span>
                <span>excessivos processados em descumprimento da lei (Art. 18(IV), LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Portabilidade de dados (Art. 18(V), LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Exclusão de dados pessoais processados com o consentimento do sujeito de
                  dados&nbsp;
                </span>
                <span>(Art. 18(VI), LGPD) (a menos que uma exceção se aplique)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Informações sobre entidades públicas e privadas com as quais foram
                  compartilhados&nbsp;
                </span>
                <span>dados pessoais (Art. 18(VII), LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Informações sobre a possibilidade de negar consentimento e as consequências de
                  tal&nbsp;
                </span>
                <span>negação (Art. 18(VIII), LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Revogação do consentimento (Art. 18(IX), LGPD)</span>
                <span>;</span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Opor-se ao processamento com base em situações de renúncia de consentimento, se{' '}
                </span>
                <span>houver descumprimento da lei. (Art. 18 §2º, LGPD)</span>
                <span>.</span>
              </li>
            </ol>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>DADOS COLETADOS</b>
            </p>
            <p>
              <span>Por meio dos seus canais digitais, a Empresa </span>
              <b>EMPRESA VILA </b>
              <span>
                poderá coletar informações fornecidas pelo Titular do Dado, tais como: NOME, EMAIL e
                TELEFONE entre outros dados necessários a plena utilização dos serviços prestados
                aos seus clientes.
              </span>
            </p>
            <p>
              <span>A </span>
              <b>EMPRESA VILA </b>
              <span>
                poderá também coletar outras informações, geradas automaticamente quando da
                utilização do site:{' '}
              </span>
              <a href="https://empresavila.com.br/">
                <span>https://empresavila.com.br/</span>
              </a>{' '}
              <span>
                e hotsites que deste sistema fazem parte, como por exemplo, mas não se limitando ao
                Device ID e ao endereço IP utilizado pelo Usuário/Cliente, com data e hora, páginas
                acessadas, informações sobre cliques nas páginas do Portal, entre outros.
              </span>
            </p>
            <p>
              <span>A </span>
              <b>EMPRESA VILA</b>
              <span>
                , ainda, poderá utilizar-se de cookies e outras tecnologias assemelhadas, sempre com
                o consentimento do Titular do Dado, para coletar de forma automática informações
                sobre os hábitos de navegação e hábitos de compra do Usuário/Cliente e suas
                preferências.&nbsp;
              </span>
            </p>
            <p>
              <span>Os dados e informações serão obtidos quando o Usuário/Cliente:</span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <span>Passar a utilizar os canais digitais da </span>
                <b>EMPRESA VILA</b>
                <span>; </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Interagir com as diversas ferramentas existentes no site, fornecendo as
                  informações voluntariamente; ou&nbsp;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Entrar em contato através dos canais de comunicação disponíveis. </span>
              </li>
            </ol>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>FINALIDADE DA COLETA DE DADOS</b>
            </p>
            <p>
              <span>Os dados e informações coletados dos Usuários/Clientes</span>
              <span>&nbsp;p</span>
              <span>oderão ser utilizados, para as seguintes finalidades: </span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Realizar, da melhor maneira, a prestação de serviços aos seus clientes;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Efetuar qualquer comunicação resultante de atividade do próprio site ou a
                  identificação do respectivo destinatário;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Fornecer acesso à área restrita do site ou suas funcionalidades exclusivas;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Cumprimento de ordem legal ou judicial; </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Constituir, defender ou exercer regularmente direitos em âmbito judicial ou
                  administrativo;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Elaborar estatísticas gerais, para identificação do perfil dos Usuários/Clientes;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Garantir a segurança do Usuário/Cliente; </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Manter atualizados os cadastros dos Usuários/Clientes</span>
                <span>
                  &nbsp;para fins de contato autorizado a ser feito por telefone, correio
                  eletrônico, SMS, mala-direta ou por outros meios de comunicação;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>Informar a respeito de eventos e comunicados da </span>
                <b>EMPRESA VILA </b>
                <span>e seus parceiros comerciais. </span>
              </li>
            </ol>
            <p>
              <span>
                Desse modo, ao aceitar a presente Política de Privacidade, o Usuário/Cliente
                manifesta{' '}
              </span>
              <span>seu consentimento livre e expresso para que a </span>
              <b>EMPRESA VILA </b>
              <span>
                utilize as informações coletadas para a adequada prestação dos serviços,
                implementação de melhorias e inovações em seus produtos e serviços, assim como para
                fins publicitários e estatísticos, conforme descrito neste documento.{' '}
              </span>
            </p>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>COMPARTILHAMENTO DE DADOS</b>
            </p>
            <p>
              <span>
                Todas as informações coletadas sobre o Usuário/Cliente são consideradas, pela{' '}
              </span>
              <b>EMPRESA VILA</b>
              <span>
                , como sigilosas, podendo ser compartilhadas com terceiros apenas e tão somente nas
                seguintes situações:
              </span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <span>Com as empresas parceiras da </span>
                <b>EMPRESA VILA</b>
                <span>
                  , tais quais, prestadores de serviços de tecnologia, entre outras, a fim de
                  viabilizar adequada prestação de serviços e entrega de produtos;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                Para a proteção dos interesses da <b>EMPRESA VILA</b>
                <span>
                  , caso necessário, em qualquer forma de conflito, extrajudicial ou judicial; ou{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                Mediante ordem judicial ou pelo requerimento de autoridades administrativas que
                detenham competência legal para sua requisição.
              </li>
            </ol>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>ARMAZENAMENTO DE DADOS</b>
              <b>&nbsp;</b>
            </p>
            <p>
              <span>Todos os dados dos Usuários/Clientes efetivamente coletados pela </span>
              <b>EMPRESA VILA </b>
              <span>
                serão armazenados em servidores próprios ou de terceiros, contratados para esta
                finalidade, no Brasil ou no exterior.{' '}
              </span>
            </p>
            <p>
              <span>Assim, a fim de garantir a segurança de seus dados, a </span>
              <b>EMPRESA VILA </b>
              <span>
                emprega todos os esforços para garantir a segurança de seus sistemas na guarda de
                referidos dados, entre eles as diretrizes sobre padrões de segurança estabelecidas
                no Decreto nº 8.771/2016, tais como:
              </span>
            </p>
            <ol>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Utilização de métodos padrões e de mercado para criptografar e anonimizar os dados
                  coletados, assim como formas padrões de encriptação para garantir sua
                  inviolabilidade;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Emprego de softwares de alta tecnologia para proteção contra acesso não autorizado
                  aos sistemas, os quais são considerados como ambiente controlado e de segurança;
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Acesso aos locais onde são armazenadas as informações possibilitando apenas às
                  pessoas previamente autorizadas, comprometidas a manter tais informações em sigilo
                  absoluto, o qual, se inobservado, acarretará responsabilidade civil e as
                  consequências de uma medida judicial nos moldes da legislação brasileira;{' '}
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Utilização de mecanismos de autenticação de acesso aos registros capazes de
                  individualizar o responsável pelo tratamento dos registros, conforme determinado
                  pelo artigo 13 do Decreto n. 8.771/2016; e
                </span>
              </li>
              <li style={{ marginLeft: '2rem' }}>
                <span>
                  Elaboração de inventário contendo o momento, a duração, a identidade do
                  funcionário ou do responsável pelo acesso designado pela empresa e o arquivo
                  acessado, com base nos registros de conexão e de acesso a aplicações, nos termos
                  do{' '}
                </span>
                <span>artigo 13 do Decreto n. 8.771/2016.</span>
              </li>
            </ol>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>ATUALIZAÇÃO E/OU EXCLUSÃO DE DADOS</b>
            </p>
            <p>
              <span>A </span>
              <b>EMPRESA VILA </b>
              <span>compromete-se a empreender seus melhores esforços para atender</span>{' '}
              <span>
                a todos os pedidos de atualização ou exclusão dentro do prazo previsto na Lei Geral
                de
              </span>{' '}
              <span>Proteção de Dados e seus regulamentos;</span>
            </p>
            <p>
              <span>
                O Usuário/Cliente poderá requerer a atualização ou exclusão dos dados coletados a
                seu respeito pela{' '}
              </span>
              <b>EMPRESA VILA</b>
              <span>, entrando em contato diretamente com o Encarregado – DPO (</span>
              <i>
                <span>Data Protection Officer</span>
              </i>
              <span>): </span>
            </p>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>CONTATO DO ENCARREGADO – DPO (DATA PROTECTION OFFICER)</b>
              <b>&nbsp;</b>
            </p>
            <p>
              <span>BLACKBELT IT SOLUTIONS SOCIEDADE LTDA</span>
              <span>
                <br />
              </span>
              <span>CNPJ/MF: 25.293.568/0001-79</span>
              <br />
              <span>Tel.: 0800 402 2002</span>
              <span>
                <br />
              </span>
              <span>Email: dpo@empresavila.com.br</span>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>
                Para as solicitações de contato com o Encarregado de Proteção de Dados (DPO),{' '}
                <a href="https://empresavila.com.br/formulario-eletronico-para-demandas-de-titular-de-dados/">
                  clique
                </a>
              </span>{' '}
              <span>
                <a href="https://empresavila.com.br/formulario-eletronico-para-demandas-de-titular-de-dados/">
                  aqui
                </a>{' '}
                para ter acesso ao
                <a href="https://empresavila.com.br/formulario-eletronico-para-demandas-de-titular-de-dados/">
                  {' '}
                  Formulário Eletrônico para Demandas de Titular de Dados
                </a>
                .
              </span>
            </p>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>CONTATO DO CONTROLADOR</b>
              <b>&nbsp;</b>
            </p>
            <p>
              <span>EMPRESA VILA,</span>
              <span>
                <br />
              </span>
              <span>SAFRA SÃO FRANCISCO ASSISTÊNCIA FUNERÁRIA LTDA. sociedade</span>{' '}
              <span>
                empresária inscrita no CNPJ/MF sob o nº 70.313.051/0001-59, com sede na Av.
              </span>{' '}
              <span>Presidente Bandeira, 487, Alecrim, CEP: 59.031-200, Natal/RN.</span>
              <span>
                <br />
              </span>
              <span>Tel.: 0800 402 2002</span>
              <span>
                <br />
              </span>
              <span>Email: contato@empresavila.com.br</span>
              <span>&nbsp;</span>
            </p>
            <p style={{ paddingTop: '0.5rem' }}>
              <b>LEGISLAÇÃO E FORO COMPETENTES</b>
              <b>&nbsp;</b>
            </p>
            <p>
              <span>
                Essa Política de Privacidade será regida, interpretada e executada de acordo com as
                leis da República Federativa do Brasil, independentemente dos conflitos dessas leis
                com leis de outros estados ou países, sendo competente o Foro de Natal, Estado de
                Rio Grande do Norte, Brasil, para dirimir qualquer dúvida decorrente deste
                instrumento. O Usuário/Cliente consente, expressamente, com a competência desse
                juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais
                privilegiado que seja ou venha a ser.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>EMPRESA VILA.&nbsp;</b>
            </p>
            <p>
              <b>Atualização: </b>
              <b>22/08/2022</b>
            </p>
            <Button variant="contained" onClick={onCloseModal} sx={{ mt: '1rem' }}>
              Fechar
            </Button>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  );
}
