export const benefitsPlans = [
  {
    title: 'Urna*',
    icon: 'emojione-monotone:funeral-urn',
    description:
      'Caixão/Ataúde, de acordo com o portfólio praticado pelo PLANO SEMPRE à época da solicitação dos serviços.',
  },
  {
    title: 'Ornamentação*',
    icon: 'fa6-solid:gears',
    description:
      'Ornamentação do ente querido com, por exemplo, flores ou cetim, de acordo com a disponibilidade.',
  },
  {
    title: 'Vestimenta',
    icon: 'icon-park-outline:clothes-cardigan',
    description: ' manto para vestir o ente querido, conhecida popularmente por mortalha.',
  },
  {
    title: 'Kit Café**',
    icon: 'ep:coffee-cup',
    description:
      ' composto minimamente por açúcar, café, chá, água, copos descartáveis e bolachas, a depender da disponibilidade.',
  },
  {
    title: 'Livro de presença',
    icon: 'icon-park-outline:bookmark-three',
    description:
      'Livro de presença padrão PLANO SEMPRE para assinatura dos visitantes, entregue à família ao final do velório.',
  },
  {
    title: 'Paramentação',
    icon: 'mdi:candelabra-fire',
    description:
      'Composta por 02 (duas) banquetas para apoio da Urna, 02 (dois) castiçais com velas e 01 (um) cristo ou salmo, tudo de acordo com a religião escolhida pela família.',
  },
  {
    title: 'Higienização*',
    icon: 'uil:raindrops-alt',
    description: 'Higienização e preparação do ente querido para o velório',
  },
  {
    title: 'Transporte do seu Ente Querido*',
    icon: 'mdi:car-convertible',
    description:
      'Remoção e cortejo do ente querido sem ônus, só por vias terrestres, considerando: transporte do ente do local do óbito para o local onde serão realizados velório e cortejo para sepultamento/cremação, configurando-se um deslocamento intermunicipal ou interestadual.',
  },
  {
    title: 'Coroa de flores*',
    icon: 'map:florist',
    description:
      '01 (uma) coroa de flores naturais ou artificiais, cuja escolha ficará a exclusivo critério do PLANO SEMPRE (de acordo com a diferenciação estipulada pelo portfólio vigente à época da solicitação do serviço)',
  },
  {
    title: 'Sala de Velório*',
    icon: 'mingcute:rest-area-fill',
    description: 'Aluguel de sala de cerimônia de despedida padrão unidades Sempre',
  },
  {
    title: 'Serviço de Velório Virtual',
    icon: 'mdi:virtual-meeting',
    description:
      'memorial virtual e transmissão de cerimônia de despedida ao vivo na plataforma personalizada Memória Viva (serviço exclusivo para unidades Sempre, a depender da disponibilidade).',
  },
  {
    title: 'Orientação de serviço de cartório',
    icon: 'fluent:clipboard-text-edit-32-regular',
    description:
      'orientação na obtenção da documentação necessária por parte da família, assim como apoio no caso de falecimento em outro estado, podendo o PLANO SEMPRE indicar outra empresa para a realização dos serviços.',
  },
];
