import { styled } from '@mui/material/styles';

import Page from 'src/components/Page';
import SimpleHeader from 'src/components/Header/SimpleHeader';

import StepFour from 'src/sections/auth/updateAccount/steps/StepFour';
import { StepOne, StepThree, StepTwo } from 'src/sections/auth/updateAccount/steps';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import useAuth from 'src/hooks/useAuth';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function UpdateAccount() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { stepOne, stepTwo, stepThree, stepFour } = useUpdateSteps();

  return (
    <Page title="Atualização">
      <RootStyle>
        <>
          <SimpleHeader />

          {stepOne ? (
            <StepOne />
          ) : stepTwo ? (
            <StepTwo />
          ) : stepThree ? (
            <StepThree />
          ) : stepFour ? (
            <StepFour />
          ) : null}
        </>
      </RootStyle>
    </Page>
  );
}
