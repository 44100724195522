/* eslint-disable array-callback-return */
import { Card, Container, Divider, Typography, useTheme } from '@mui/material';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { UnitsList } from 'src/sections/@dashboard/units/UnitsList';
import useSettings from 'src/hooks/useSettings';
import axiosInstance from 'src/utils/axios';

let UnitsAtend: any = [];
let UnitsCemit: any = [];
let UnitsVelor: any = [];

let statesUF: any = [];
let states: any = [];
let city: any = [];

export function Units() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const { themeStretch } = useSettings();

  const [atendimento, setAtendimento] = useState(true);
  const [velorio, setVelorio] = useState(true);
  const [cemiterio, setCemiterio] = useState(true);

  // const [latCenter, setLatCenter] = useState(-15.7701547);
  // const [lngCenter, setLngCenter] = useState(-47.9547604);
  // const [zoomMaps, setZoomMaps] = useState(3.5);
  const [latCenter, setLatCenter] = useState(-6.177617);
  const [lngCenter, setLngCenter] = useState(-36.383575);
  const [zoomMaps, setZoomMaps] = useState(7);

  const [activeMarker, setActiveMarker] = useState(null);
  const [open, setOpen] = useState(true);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [originLat, setOriginLat] = useState<number | null>(null);
  const [originLng, setOriginLng] = useState<number | null>(null);
  const [destinationLat, setDestinationLat] = useState<number | null>(null);
  const [destinationLng, setDestinationLng] = useState<number | null>(null);

  async function setLogViewUnitsPage() {
    await axiosInstance.get(`/users/log/units`);
  }

  navigator.geolocation.getCurrentPosition((position) => {
    setOriginLat(position.coords.latitude);
    setOriginLng(position.coords.longitude);
  });

  function hrefMaps(url: string) {
    if (activeMarker) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  async function getByState(value: string) {
    setRemoveLoading(false);
    setOpen(true);
    let { data } = await axiosInstance.get(`/users/units/state/${value}`);

    let cityAll: any = [];
    city = [];

    data.map((i: any) => {
      cityAll.push(i.city);
    });

    if (value !== 'all') {
      city.push(...new Set(cityAll));
      setOpen(false);
    }

    UnitsAtend = [];
    UnitsCemit = [];
    UnitsVelor = [];
    data.map((i: any) => {
      if (i.type === 'ATENDIMENTO') {
        UnitsAtend.push(i);
      } else if (i.type === 'CEMITERIO') {
        UnitsCemit.push(i);
      } else if (i.type === 'VELORIO') {
        UnitsVelor.push(i);
      }
    });

    setRemoveLoading(true);
  }

  async function getByCity(value: string) {
    setRemoveLoading(false);
    setOpen(true);
    var { data } = await axiosInstance.get(`/users/units/city/${value}`);

    // setLatCenter(data[0].lat);
    // setLngCenter(data[0].lng);

    UnitsAtend = [];
    UnitsCemit = [];
    UnitsVelor = [];
    data.map((i: any) => {
      if (i.type === 'ATENDIMENTO') {
        UnitsAtend.push(i);
      } else if (i.type === 'CEMITERIO') {
        UnitsCemit.push(i);
      } else if (i.type === 'VELORIO') {
        UnitsVelor.push(i);
      }
    });
    setOpen(false);
    setRemoveLoading(true);
  }

  const handleActiveMarker = (marker: any, lat: number, lng: number) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setDestinationLat(lat);
    setDestinationLng(lng);
  };

  const position = {
    lat: latCenter,
    lng: lngCenter,
  };

  const getUnits = async () => {
    await setLogViewUnitsPage();

    UnitsAtend = [];
    UnitsCemit = [];
    UnitsVelor = [];

    var { data } = await axiosInstance.get('/users/units/list');

    data.map((i: any) => {
      if (i.type === 'ATENDIMENTO') {
        UnitsAtend.push(i);
      } else if (i.type === 'CEMITERIO') {
        UnitsCemit.push(i);
      } else if (i.type === 'VELORIO') {
        UnitsVelor.push(i);
      }
    });

    let allUF: any = [];
    let allStates: any = [];

    data.map((i: any) => {
      allUF.push(i.state[1]);
      allStates.push(i.state[0]);
    });

    statesUF = [];
    states = [];

    statesUF.push(...new Set(allUF));
    states.push(...new Set(allStates));

    setRemoveLoading(true);
  };

  const setMap = () => {
    setLatCenter(-6.177617);
    setLngCenter(-36.383575);
    setZoomMaps(7);
    setActiveMarker(null);
  };

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ width: '100%', height: '100vh' }} />
      <div style={{ width: '100%', height: '100vh', position: 'absolute', top: '0', left: '0' }}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
          libraries={['places']}
        >
          <GoogleMap
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={position}
            zoom={zoomMaps}
          >
            <div>
              <Card
                style={{
                  width: '80%',
                  maxWidth: '320px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: isDesktop ? '20px 300px 0' : '65px 30px 0',
                  padding: '5px',
                  zIndex: isDesktop ? '10000' : undefined,
                }}
              >
                <div
                  style={{
                    marginRight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => navigate(PATH_DASHBOARD.general.app)}
                >
                  <Iconify icon="eva:arrow-ios-back-outline" fontSize="20px" />
                </div>
                <Typography variant="h5" fontSize={'14px'}>
                  Onde estamos
                </Typography>
              </Card>

              <div
                style={{
                  width: '80%',
                  maxWidth: '320px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: isDesktop ? '5px 300px 0' : '5px 30px 0',
                  position: 'absolute',
                  zIndex: isDesktop ? '10000' : undefined,
                }}
              >
                <select
                  onChange={(e) => {
                    getByState(e.target.value);
                  }}
                  name="filtroState"
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    width: '48%',
                    borderColor: theme.palette.grey[500],
                    backgroundColor: 'white',
                    color: theme.palette.primary.main,
                  }}
                >
                  <option value="" hidden>
                    Estado
                  </option>
                  <option value="all">Todos</option>
                  {statesUF.sort().map((i: any) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    getByCity(e.target.value);
                  }}
                  disabled={open}
                  name="filtroCity"
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    width: '48%',
                    backgroundColor: 'white',
                    borderColor: theme.palette.grey[500],
                    color: theme.palette.primary.main,
                  }}
                >
                  <option value="" hidden>
                    Cidade
                  </option>
                  {city.sort().map((i: any) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {atendimento &&
              UnitsAtend.map((i: any) => (
                <Marker
                  key={i.id}
                  position={{ lat: Number(i.lat), lng: Number(i.lng) }}
                  title={i.title}
                  icon="/assets/icons/marcadorAtendimento.png"
                  onClick={() => {
                    setLatCenter(Number(i.lat) + 15.0);
                    setLngCenter(Number(i.lng));
                    handleActiveMarker(i.id, i.lat, i.lng);
                  }}
                >
                  {activeMarker === i.id ? (
                    <InfoWindow
                      key={`info-window-${i.id}`}
                      onUnmount={() => setMap()}
                      onCloseClick={() => setMap()}
                    >
                      <CardInfoWindow
                        title={i.title}
                        adress={i.adress}
                        number={i.number}
                        city={i.city}
                        state={i.state}
                        phone={i.phone_number}
                      />
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
            {velorio &&
              UnitsVelor.map((i: any) => (
                <Marker
                  key={i.id}
                  position={{ lat: Number(i.lat), lng: Number(i.lng) }}
                  title={i.title}
                  icon="/assets/icons/marcadorVelorio.png"
                  onClick={() => handleActiveMarker(i.id, i.lat, i.lng)}
                >
                  {activeMarker === i.id ? (
                    <InfoWindow
                      key={`info-window-${i.id}`}
                      onUnmount={() => setMap()}
                      onCloseClick={() => setMap()}
                    >
                      <CardInfoWindow
                        title={i.title}
                        adress={i.adress}
                        number={i.number}
                        city={i.city}
                        state={i.state}
                        phone={i.phone_number}
                      />
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
            {cemiterio &&
              UnitsCemit.map((i: any) => (
                <Marker
                  key={i.id}
                  position={{ lat: Number(i.lat), lng: Number(i.lng) }}
                  title={i.title}
                  icon="/assets/icons/marcadorCemiterio.png"
                  onClick={() => handleActiveMarker(i.id, i.lat, i.lng)}
                >
                  {activeMarker === i.id ? (
                    <InfoWindow
                      key={`info-window-${i.id}`}
                      onUnmount={() => setMap()}
                      onCloseClick={() => setMap()}
                    >
                      <CardInfoWindow
                        title={i.title}
                        adress={i.adress}
                        number={i.number}
                        city={i.city}
                        state={i.state}
                        phone={i.phone_number}
                      />
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
            <div>
              <div
                style={{
                  width: '100%',
                  maxWidth: '320px',
                  padding: '0 10px',
                  position: 'absolute',
                  right: 0,
                  bottom: 105,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CardMarker
                  onclick={() =>
                    hrefMaps(
                      `https://www.google.com/maps/dir/${originLat},${originLng}/${destinationLat},${destinationLng}`
                    )
                  }
                  image={'/assets/icons/compass.png'}
                  text="Rota"
                  disabled={activeMarker ? false : true}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  maxWidth: '320px',
                  padding: '0 10px',
                  position: 'absolute',
                  right: 0,
                  bottom: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <CardMarker
                  onclick={() => {
                    setAtendimento(true);
                    setVelorio(true);
                    setCemiterio(true);
                  }}
                  image={'/assets/icons/groupMarcador.png'}
                  text="Ver tudo"
                />
                <CardMarker
                  onclick={() => {
                    setAtendimento(true);
                    setVelorio(false);
                    setCemiterio(false);
                  }}
                  image={'/assets/icons/marcadorAtendimento.png'}
                  text="Atendimento"
                />
                <CardMarker
                  onclick={() => {
                    setAtendimento(false);
                    setVelorio(true);
                    setCemiterio(false);
                  }}
                  image={'/assets/icons/marcadorVelorio.png'}
                  text="Velório"
                />
                <CardMarker
                  onclick={() => {
                    setAtendimento(false);
                    setVelorio(false);
                    setCemiterio(true);
                  }}
                  image={'/assets/icons/marcadorCemiterio.png'}
                  text="Cemitério"
                />
              </div>
            </div>
          </GoogleMap>
        </LoadScript>
      </div>

      <Container
        maxWidth={themeStretch ? false : 'lg'}
        style={{
          width: '100vw',
          height: '300vh',
          margin: '0 auto',
          padding: '20px',
        }}
      >
        <Divider />
        <Typography variant="overline" sx={{ color: '#637381' }}>
          nossas unidades
        </Typography>

        {removeLoading &&
          states.sort().map((i: any) => (
            <Card key={i} style={{ width: '100%', borderRadius: 0, padding: '10px' }}>
              <UnitsList state={i} />
            </Card>
          ))}
      </Container>
    </>
  );
}
interface CardMarkeProps {
  onclick: () => void;
  image: string;
  text: string;
  disabled?: boolean;
}
function CardMarker({ onclick, image, text, disabled = false }: CardMarkeProps) {
  return (
    <Card
      onClick={onclick}
      style={{
        opacity: disabled ? '40%' : '100%',
        cursor: disabled ? 'not-allowed' : 'pointer',
        width: '72px',
        height: '72px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
      }}
    >
      <img src={image} alt={text} />
      <p style={{ fontSize: '10px' }}>{text}</p>
    </Card>
  );
}

interface CardInfoWindowProps {
  title: string;
  adress: string;
  number: string;
  city: string;
  state: string;
  phone: string;
}

function CardInfoWindow({ title, adress, number, city, state, phone }: CardInfoWindowProps) {
  const theme = useTheme();
  return (
    <div style={{ zIndex: 100 }}>
      <h3 style={{ color: `${theme.palette.primary.main}`, fontSize: '14px' }}>{title}</h3>
      <h4>Serviços</h4>
      <p>Atendimento ao cliente</p>
      <h4>Endereço</h4>
      <p>{`${adress},${number}, ${city} - ${state}, Brasil`}</p>

      <h4>Horário de Atendimento</h4>
      <p>Seg a Sex das 07:30 às 17:30 | sáb das 07:30 às 11:30 e Centro Velório 24h</p>
      <h4>Telefone</h4>
      <p>CAPITAIS E REGIÕES METROPOLITANAS: (84) 4000-2002</p>
      <p>DEMAIS LOCALIDADES: 0800 402 2002</p>
    </div>
  );
}

