import { Container, Tab, Box, Tabs, Divider } from '@mui/material';
import { useNavigate } from 'react-router';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useTabs from 'src/hooks/useTabs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
  ProfileGeneralSettings,
  ProfileChangePassword,
} from 'src/sections/@dashboard/user/profile';

export default function UserSettings() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();

  const { currentTab, onChangeTab } = useTabs('Dados gerais');

  const ACCOUNT_TABS = [
    {
      value: 'Dados gerais',
      icon: <Iconify icon={'bx:user'} width={20} height={20} />,
      component: <ProfileGeneralSettings />,
    },
    {
      value: 'Alteração de senha',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <ProfileChangePassword />,
    },
  ];

  return (
    <Page title="User: Account Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
          heading="Configurações"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Configurações' }]}
        />
        <Divider />
        <Tabs value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.value}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Divider />
        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
