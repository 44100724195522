import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PATH_AUTH } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import InputMask from 'react-input-mask';
import { regexCamp } from '../updateAccount/UpdateInitialForm';

// ----------------------------------------------------------------------

type FormValuesProps = {
  cpf: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

interface LoginFormProps {
  handleOpenModal: () => void;
}

export default function LoginForm({ handleOpenModal }: LoginFormProps) {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    cpf: Yup.string().required('CPF é Obrigatório').test("Digite o CPF corretamente!", "Digite o CPF corretamente!", (value: any) => !regexCamp(value)),
    password: Yup.string().required('Senha é obrigatório'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  });

  const {
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.cpf.replace(/[^0-9]/g, ''), data.password);
      window.location.reload();
    } catch (error) {
      if (error.message === 'Sua sessão gratuita expirou!') {
        handleOpenModal();
      } else if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            CPF
          </Typography>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask mask="999.999.999-99" value={value} onChange={onChange}>
                <TextField
                  name="cpf"
                  type="text"
                  fullWidth
                  error={Boolean(errors.cpf)}
                  helperText={error?.message}
                />
              </InputMask>
            )}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            SENHA
          </Typography>
          <RHFTextField
            name="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Lembra-me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Esqueci minha senha
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Entrar
      </LoadingButton>
    </FormProvider>
  );
}
