import { Card, Typography, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { isMobileSafari, isOpera, isSafari } from 'react-device-detect';
import { toast } from 'react-toastify';
import useAuth from 'src/hooks/useAuth';
import axiosInstance from 'src/utils/axios';

interface CardNewsProps {
  id?: string;
  image: string;
  title: string;
  link: string;
  width?: string;
  height?: string;
}

export default function CardBanners({ image, title, link, id, width, height }: CardNewsProps) {
  const [loadingLecupon, setLoadingLecupon] = useState(false);

  const { user } = useAuth();

  async function handleClickCard() {
    if (link === 'https://sempremaisbeneficios.planosempre.com.br') {
      lecupon();
    } else {
      if (isSafari || isMobileSafari || isOpera) {
        window.location.assign(link);
      } else {
        window.open(link, '_blank');
      }
    }
  }

  async function lecupon() {
    setLoadingLecupon(true);
    try {
      const response = await axiosInstance.get(`/users/lecupon/getlecupon/${user.cpf}`);

      if (isSafari || isMobileSafari || isOpera) {
        window.location.assign(response.data.web_smart_link);
      } else {
        window.open(response.data.web_smart_link, '_blank');
      }

      setLoadingLecupon(false);
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingLecupon(false);
    }
  }
  return (
    <Card
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: width ? width : '360px',
        height: height ? height : '230px',
        margin: '10px',
        position: 'relative',
      }}
      onClick={() => handleClickCard()}
    >
      {!loadingLecupon ? (
        <div
          style={{
            position: 'absolute',
            top: '120px',
            left: '7px',
            display: 'none',
          }}
        >
          <Typography variant={'subtitle2'} fontWeight={600} fontSize="13px" color="white">
            {title}
          </Typography>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            opacity: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          <CircularProgress />
        </div>
      )}
    </Card>
  );
}

