import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
import { UpdateInitialForm } from 'src/sections/auth/updateAccount';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function StepOne() {
  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} textTransform={'uppercase'}>
              Etapa 1 de 4
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{ mt: 3 }}
              fontWeight="bold"
              marginBottom="20px"
            >
              Precisamos validar se você é um cliente cadastrado, para isso informe os seguintes
              dados nos campos abaixo.
            </Typography>
          </Box>
        </Stack>
        <UpdateInitialForm />
      </ContentStyle>
    </Container>
  );
}
