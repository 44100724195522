import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TagManager from 'react-gtm-module';
import Page from 'src/components/Page';
import SimpleHeader from 'src/components/Header/SimpleHeader';
import RegisterLeadForm from 'src/sections/auth/registerLead/RegisterLeadForm';
import { useState } from 'react';
import { PrivateModal } from 'src/sections/auth/PrivateModal';
import { PrivacyModal } from 'src/sections/auth/PrivateModal/Privacity';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export function RegisterLead() {
  const [openPrivateModal, setOpenPrivateModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-T7DLKZX',
  };

  TagManager.initialize(tagManagerArgs);

  function handleOpenPrivateModal() {
    setOpenPrivateModal(true);
  }

  function handleClosePrivateModal() {
    setOpenPrivateModal(false);
  }

  function handleOpenPrivacyModal() {
    setOpenPrivacyModal(true);
  }

  function handleClosePrivacyModal() {
    setOpenPrivacyModal(false);
  }

  return (
    <Page title="Cadastro Lead">
      <RootStyle>
        <SimpleHeader />
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  fontSize={'20px'}
                  lineHeight={'30px'}
                  fontWeight={'600'}
                  marginBottom={'10px'}
                >
                  Seja bem-vindo(a) a área do cliente!
                </Typography>
                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'10x'}
                >
                  Experimente a área do cliente durante 07 dias, de forma gratuita.
                </Typography>

                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'1rem'}
                >
                  Aproveite nosso clube de benefícios e confira agora mesmo os parceiros da sua
                  região!
                </Typography>

                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'1rem'}
                >
                  Cadastre os seu dados.
                </Typography>
              </Box>
            </Stack>

            <RegisterLeadForm
              handleOpenPrivateModal={handleOpenPrivateModal}
              handleOpenPrivacyModal={handleOpenPrivacyModal}
            />

            <Typography
              variant="body1"
              align="left"
              sx={{ mt: 3 }}
              fontWeight="bold"
              marginBottom="20px"
            >
              Já possui cadastro e quer acessar o portal?
            </Typography>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              href="/auth/login"
            >
              Entrar
            </LoadingButton>
          </ContentStyle>
          <PrivateModal open={openPrivateModal} onCloseModal={handleClosePrivateModal} />
          <PrivacyModal open={openPrivacyModal} onCloseModal={handleClosePrivacyModal} />
        </Container>
      </RootStyle>
    </Page>
  );
}
