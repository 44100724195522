import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Typography, Divider, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import useAuth from '../../../../hooks/useAuth';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axiosInstance from 'src/utils/axios';
import { PasswordRequirement } from 'src/sections/auth/reset-password/PasswordRequirement';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  confirmPassword: string;
  password: string;
  afterSubmit?: string;
};

const regexPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm;

export default function ProfileChangePassword() {
  const { user } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const UpdateUserSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Digite a senha atual').nullable(),
    password: Yup.string()
      .required('Digite uma senha')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),
    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post(`/users/comparePasswords/${user.id}`, {
        oldPassword: data.oldPassword,
      });
      await axiosInstance.patch(`/users/${user.id}`, {
        password: data.password,
      });
      toast.success('Senha Alterada com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      setTimeout(() => {
        window.location.href = PATH_DASHBOARD.general.app;
      });
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card
        sx={{
          width: '100%',
          padding: '20px',
        }}
      >
        <Typography
          variant="body1"
          textTransform={'uppercase'}
          margin="10px"
          fontSize="12px"
          sx={{
            mt: 0,
            mx: 'auto',
            display: 'block',
            textAlign: 'left',
            color: 'text.secondary',
          }}
        >
          Alteração de senha
        </Typography>
        <Divider sx={{ borderStyle: 'dashed', marginBottom: '10px' }} />
        <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
          <RHFTextField
            name="oldPassword"
            label="Senha atual"
            type={showOldPassword ? 'text' : 'password'}
            style={{ margin: '10px 0' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                    <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="password"
            label="Nova Senha"
            type={showPassword ? 'text' : 'password'}
            style={{ margin: '10px 0' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="confirmPassword"
            label="Confirmar senha"
            type={showPassword ? 'text' : 'password'}
            style={{ margin: '10px 0' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <PasswordRequirement />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Salvar nova senha
          </LoadingButton>
        </div>
      </Card>
    </FormProvider>
  );
}
