// --- IMPORTS
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AiOutlineCloseCircle, AiOutlineFilePdf } from 'react-icons/ai';
import { Card, Checkbox, Dialog, DialogContent, FormControlLabel, useTheme } from '@mui/material';
import IPlan from 'src/pages/dashboard/@types';
import moment from 'moment';
import axiosInstance from 'src/utils/axios';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';

// --- COMPONENTE PRINCIPAL

const ViewContractModal = (props: {
  plan: IPlan;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}) => {
  // --- CONSTANTES

  const { plan, open, setOpen } = props;
  const theme = useTheme();

  // --- FUNÇÕES

  const handleReadContract = async (id: string) => {
    try {
      await axiosInstance.post('users/contract/' + id, {
        read_at: new Date().toISOString(),
      });
      toast.success('Contrato marcado como lido!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    } catch (error) {
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };
  // --- JSX
  return (
    <Dialog onClose={setOpen} open={open} fullWidth={true} maxWidth="xl">
      <DialogContent>
        <Card
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <embed
            src={plan.contract_file_url}
            width="100%"
            height="700px"
            style={{ borderRadius: 15 }}
          />
        </Card>
        <Box
          mt={1}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {plan.read ? (
            <Box display="flex" flexDirection="row" mt={2}>
              <Typography
                variant="body2"
                fontWeight="400"
                color={theme.palette.grey[800]}
                fontSize="14px"
                display="flex"
                flexDirection="row"
                fontStyle="italic"
                mr={0.5}
              >
                leitura confirmada em
              </Typography>
              <Typography
                variant="body2"
                fontWeight="900"
                color={theme.palette.grey[800]}
                fontSize="14px"
                display="flex"
                flexDirection="row"
                fontStyle="italic"
              >
                {moment(plan.read_at).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={plan.read}
                  onChange={() => handleReadContract(plan.id)}
                  name="Confirmação de leitura"
                />
              }
              label="Confirmo a leitura do contrato"
            />
          )}
          <Box display="flex" flexDirection="row">
            <Button
              startIcon={<AiOutlineFilePdf />}
              variant="contained"
              sx={{
                background: theme.palette.primary.dark,
                marginTop: '1rem',
                maxWidth: '400px',
              }}
              onClick={async () => {
                await fetch(plan.contract_file_url, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/pdf',
                  },
                })
                  .then((response) => response.blob())
                  .then((blob) => {
                    // Cria o blob do PDF
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Contrato ${plan.name}.pdf`);
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                  });
              }}
            >
              Baixar Contrato
            </Button>
            <Button
              startIcon={<AiOutlineCloseCircle />}
              variant="contained"
              sx={{
                background: theme.palette.grey[600],
                marginTop: '1rem',
                maxWidth: '400px',
                marginLeft: '1rem',
              }}
              onClick={async () => setOpen(false)}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewContractModal;

