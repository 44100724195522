import { Dialog, DialogContent, Typography, Button, useTheme, IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';

interface BenefitsModalProps {
  onClose: () => void;
  open: boolean;
}

export function BenefitsModal({ onClose, open }: BenefitsModalProps) {
  const theme = useTheme();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent
        sx={{
          maxWidth: '360px',
          padding: '35px 16px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <IconButton sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={onClose}>
          <Iconify icon="clarity:window-close-line" />
        </IconButton>
        <Typography variant="h6">
          Seu prazo de{' '}
          <span style={{ color: theme.palette.secondary.main }}>07 dias gratuitos </span> <br />{' '}
          para acesso a área do cliente se encerrou...
        </Typography>

        <Typography variant="body1" sx={{ my: '1rem' }}>
          Entre em contato com uma de nossas consultoras e seja um cliente Sempre!
        </Typography>

        <Button
          variant="contained"
          sx={{ background: theme.palette.secondary.main }}
          onClick={() => window.location.assign('https://www.planosempre.com.br')}
        >
          Torne-se um cliente
        </Button>
      </DialogContent>
    </Dialog>
  );
}
