import {
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import axiosInstance from 'src/utils/axios';

interface UnitsListProps {
  state: string;
}
let units: any = [];
let allUnits: any = [];

export function UnitsList({ state }: UnitsListProps) {
  const [unitsCity, setUnitsCity] = useState([]);

  const getByState: any = async (value: string) => {
    units = [];
    var { data } = await axiosInstance.get(`/users/units/state/${value}`);

    allUnits = [];

    // eslint-disable-next-line array-callback-return
    data.map((i: any) => {
      allUnits.push(i.city);
    });

    units.push(...new Set(allUnits));
  };
  const getByCity = async (value: string) => {
    let { data } = await axiosInstance.get(`/users/units/city/${value}`);

    setUnitsCity(data);
  };

  console.log(unitsCity.filter((e: any) => e.type === 'ATENDIMENTO').length);

  useEffect(() => {
    getByState();
  }, []);

  const theme = useTheme();
  return (
    <Accordion>
      <AccordionSummary
        onClick={() => getByState(state)}
        expandIcon={
          <Iconify
            icon={'eva:arrow-ios-downward-fill'}
            width={20}
            height={20}
            color={theme.palette.primary.main}
          />
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body1"
            style={{ color: theme.palette.primary.main, fontWeight: 600, fontSize: '16px' }}
          >
            {state}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {units.sort().map((unit: any) => (
          <Accordion key={unit}>
            <AccordionSummary onClick={() => getByCity(unit)}>{unit}</AccordionSummary>
            <AccordionDetails>
              {unitsCity.filter((e: any) => e.type === 'ATENDIMENTO').length > 0 && (
                <Accordion>
                  <AccordionSummary>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <img
                        src="/assets/icons/marcadorAtendimento.png"
                        alt="Unidades"
                        width="32px"
                      />
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Unidades de Atendimento
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {unitsCity
                      .filter((e: any) => e.type === 'ATENDIMENTO')
                      .map((unit: any) => (
                        <Accordion key={unit.id}>
                          <AccordionSummary>
                            <Typography variant="body1">{unit.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <InfoUnits unit={unit} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              )}
              {unitsCity.filter((e: any) => e.type === 'VELORIO').length > 0 && (
                <Accordion>
                  <AccordionSummary>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <img src="/assets/icons/marcadorVelorio.png" alt="Unidades" width="32px" />
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Centros de Velório
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {unitsCity
                      .filter((e: any) => e.type === 'VELORIO')
                      .map((unit: any) => (
                        <Accordion key={unit.id}>
                          <AccordionSummary>
                            <Typography variant="body1">{unit.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <InfoUnits unit={unit} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              )}
              {unitsCity.filter((e: any) => e.type === 'CEMITERIO').length > 0 && (
                <Accordion>
                  <AccordionSummary>
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <img src="/assets/icons/marcadorCemiterio.png" alt="Unidades" width="32px" />
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Cemitérios
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {unitsCity
                      .filter((e: any) => e.type === 'CEMITERIO')
                      .map((unit: any) => (
                        <Accordion key={unit.id}>
                          <AccordionSummary>
                            <Typography variant="body1">{unit.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <InfoUnits unit={unit} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

interface InfoUnitsProps {
  unit: {
    adress: string;
    number: string;
    city: string;
    state: string;
  };
}

const InfoUnits = ({ unit }: InfoUnitsProps) => (
  <Card sx={{ zIndex: 100 }}>
    <h4>Serviços</h4>
    <p>Atendimento ao cliente</p>
    <h4>Endereço</h4>
    <p>{`${unit.adress},${unit.number}, ${unit.city} - ${unit.state}, Brasil`}</p>

    <h4>Horário de Atendimento</h4>
    <p>Seg a Sex das 07:30 às 17:30 | sáb das 07:30 às 11:30 e Centro Velório 24h</p>
    <h4>Telefone</h4>
    <p>
      CAPITAIS E REGIÕES METROPOLITANAS: <a href="tel:8440002002">(84) 4000-2002</a>
    </p>
    <p>
      DEMAIS LOCALIDADES: <a href="tel:08004022002">0800 402 2002</a>
    </p>
  </Card>
);

