import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, Typography, styled, Box, Slider } from '@mui/material';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import { IoCloseOutline } from 'react-icons/io5';
import { CustomFile } from 'src/components/upload/type';
import getCroppedImg from './functions';

interface ProfileImageCropProps {
  onClose: () => void;
  open: boolean;
  imageURL: CustomFile | undefined;
  handleSaveCroppedImage: (file: any) => void;
}

export function ProfileImageCrop({
  onClose,
  open,
  imageURL,
  handleSaveCroppedImage,
}: ProfileImageCropProps) {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCroppedImage = async () => {
    if (imageURL?.preview)
      try {
        const croppedImage: any = await getCroppedImg(
          imageURL.preview,
          croppedAreaPixels,
          rotation
        );
        handleSaveCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
  };

  const Header = styled('div')(({ theme }) => ({}));

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogContent sx={{ padding: '0px' }}>
        <Header
          sx={{
            padding: '20px 20px 24px 20px',
            borderBottom: '1px solid  rgba(145, 158, 171, 0.24)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Enviar imagem</Typography>

          <IoCloseOutline size={24} onClick={onClose} style={{ cursor: 'pointer' }} />
        </Header>
        <Box sx={{ width: '100%', height: '500px', marginTop: '8px', padding: '20px' }}>
          <Cropper
            image={imageURL ? imageURL.preview : ''}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                marginTop: 70,
                marginBottom: 260,
              },
            }}
          />
        </Box>
        <Box sx={{ marginTop: '8px', padding: '20px' }}>
          <Typography variant="body1" sx={{ my: '1rem' }} textAlign="center">
            Selecione a parte da imagem que deseja cortar
          </Typography>
          <Box padding={1}>
            <Typography variant="overline" sx={{ my: '5rem' }}>
              Ampliar imagem
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(Number(zoom))}
            />
          </Box>
          <Box padding={1}>
            <Typography variant="overline" sx={{ my: '5rem' }}>
              Rotacionar
            </Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Zoom"
              onChange={(e, rotation) => setRotation(Number(rotation))}
            />
          </Box>
          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={false}
            onClick={() => handleCroppedImage()}
          >
            Salvar imagem
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

