import Dialog from '@mui/material/Dialog';
import { IoCloseOutline } from 'react-icons/io5';
import {
  Typography,
  DialogContent,
  Box,
  Button,
  CircularProgress,
  useTheme,
  Card,
  Popover,
  Stack,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Parcel } from 'src/pages/dashboard/HistoricPayments';
import moment from 'moment';
import axiosInstance from 'src/utils/axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineDollar, AiOutlineCloudDownload, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsQrCode, BsPhone } from 'react-icons/bs';
import { BiBarcodeReader } from 'react-icons/bi';
import { ImCopy } from 'react-icons/im';
import { SiPix } from 'react-icons/si';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';

interface IGetBolecode {
  RETORNOS: [
    {
      CODBAR: string;
      LDIGIT: string;
      VALOR: 76.45;
      VENCIMENTO: string;
      BOLETO_LINK: string;
      BOLETO_PIX: string;
    }
  ];
}

const tabInitial = {
  barcode: true,
  pix: false,
  card: false,
};

// ----------------------------- COMPONENTE PRINCIPAL -------------------------------------
export interface FormDialogProps {
  open: boolean;
  onClose: () => void;
  parcel: Parcel;
  getHistoric: () => Promise<void>;
  setReloadNextPayment: Dispatch<SetStateAction<boolean>>;
}

export function ModalPayments(props: FormDialogProps) {
  // Constantes
  const theme = useTheme();
  const { onClose, open, getHistoric, setReloadNextPayment } = props;

  // --- hooks
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showPayment, setShowPayment] = useState(tabInitial);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paym, setPaym] = useState<Parcel>(props.parcel);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  // ----------------------------- FUNÇÕES -------------------------------------
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClose = async (reload: boolean) => {
    onClose();
    setShowPayment(tabInitial);

    if (reload) {
      setReloadNextPayment(true);
      await getHistoric();
    }
  };

  const downloadBoleto = () => {
    if (paym.ticket_url) {
      const a = Object.assign(document.createElement('a'), {
        href: paym.ticket_url,
        style: 'display:none',
        download: 'boleto.pdf',
      });
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const handleGetBolecode = async () => {
    setLoadingPayment(true);
    await axiosInstance
      .post('/users/getBolecode', {
        company: props.parcel.company,
        filial: props.parcel.filial,
        num: props.parcel.num,
        prefix: props.parcel.prefix,
        installment: props.parcel.installment,
        type: props.parcel.type,
        bussines: props.parcel.bussines,
      })
      .then(async (response) => {
        const { RETORNOS }: IGetBolecode = response.data;
        if (RETORNOS[0]) {
          const data = RETORNOS[0];
          setPaym({
            ...paym,
            due_date: data.VENCIMENTO,
            total: String(data.VALOR),
            barcode: data.CODBAR,
            barcode_line: data.LDIGIT,
            copy_pix: RETORNOS[0].BOLETO_PIX.trim(),
            ticket_url: RETORNOS[0].BOLETO_LINK,
          });
          toast.success(`Dados do pagamento atualizados com sucesso!`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        } else {
          await handleClose(false);
          toast.info(`O pagamento não foi necessário ser atualizado`, {
            position: toast.POSITION.TOP_RIGHT,
            theme: 'colored',
          });
        }
      })
      .catch(async (error) => {
        setLoadingPayment(false);
        await handleClose(false);
        toast.error(`Ocorreu um erro ao buscar o pagamento: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      });
    setLoadingPayment(false);
  };

  useEffect(() => {
    handleGetBolecode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parcel]);

  interface IPaymentData {
    due_date_corR: string;
    date: string;
    value: string;
  }

  const PaymentData = ({ date, value, due_date_corR }: IPaymentData) => (
    //  ------------------- Valor e data da parcela -------------------
    <Stack
      marginTop="10px"
      textAlign="center"
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 4, md: 2 }}
    >
      <Box>
        <Typography
          variant="body2"
          fontWeight="400"
          color={theme.palette.grey[800]}
          fontSize="14px"
        >
          Mês da parcela
        </Typography>
        <Typography
          variant="body2"
          fontWeight="700"
          color={theme.palette.grey[800]}
          fontSize="18px"
        >
          {moment(due_date_corR).format('MMM/YYYY').toUpperCase()}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          fontWeight="400"
          color={theme.palette.grey[800]}
          fontSize="14px"
        >
          Vencimento
        </Typography>
        <Typography
          variant="body2"
          fontWeight="700"
          color={theme.palette.grey[800]}
          fontSize="18px"
        >
          {moment(date).format('DD/MM/YYYY')}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          fontWeight="400"
          color={theme.palette.grey[800]}
          fontSize="14px"
        >
          Valor
        </Typography>
        <Typography
          variant="body2"
          fontWeight="700"
          color={theme.palette.grey[800]}
          fontSize="18px"
        >
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(value))}
        </Typography>
      </Box>
    </Stack>
  );

  // ----------------------------- JSX -------------------------------------
  return (
    <Dialog onClose={async () => handleClose(true)} open={open} fullWidth maxWidth="sm">
      <DialogContent sx={{ padding: '0px' }}>
        <div
          style={{
            padding: '20px 20px 24px 20px',
            borderBottom: '1px solid  rgba(145, 158, 171, 0.24)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <AiOutlineDollar style={{ width: 18, height: 18, marginRight: 5 }} /> Formas de
            pagamento{' '}
          </Typography>

          <IoCloseOutline
            size={24}
            onClick={async () => handleClose(true)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {/*  ------------------- Formas de pagamento ------------------- */}

        {paym.copy_pix.length > 0 && (
          <>
            {moment(new Date()).diff(moment(paym.due_date), 'days') <= 45 && (
              <Stack
                display="flex"
                justifyContent="center"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  padding: '20px',
                  paddingBottom: '5px',
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<BiBarcodeReader style={{ height: 34, width: 34 }} />}
                  style={{
                    height: 80,
                    textAlign: 'start',
                    width: '100%',
                    backgroundColor: showPayment.barcode ? theme.palette.action.hover : '',
                  }}
                  onClick={() => setShowPayment({ barcode: true, card: false, pix: false })}
                >
                  Pagamento por boleto
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<SiPix style={{ height: 34, width: 34 }} />}
                  style={{
                    height: 80,
                    textAlign: 'start',
                    width: '100%',
                    backgroundColor: showPayment.pix ? theme.palette.action.hover : '',
                  }}
                  onClick={() => setShowPayment({ barcode: false, card: false, pix: true })}
                >
                  Pagamento por PIX
                </Button>
                {/* <Button
              variant="outlined"
              startIcon={<BsCreditCard2Back style={{ height: 34, width: 34 }} />}
              style={{ height: 80, textAlign: 'start' }}
              onClick={() => setShowPayment({ barcode: false, card: true, pix: false })}
              disabled
            >
              Pagamento por Cartão
            </Button> */}
              </Stack>
            )}
          </>
        )}

        {/*  ------------------- Card de pagamentos ------------------- */}
        {showPayment.barcode && (
          <Card
            sx={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              margin: 2,
            }}
          >
            <Card
              style={{
                width: 200,
                padding: 10,
                boxShadow: `0 0 1px 0`,
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* bar code */}
              <BiBarcodeReader style={{ fontSize: 120, color: theme.palette.primary.main }} />
              {/* <Barcode
                    value="34194941200000024771093695541748380015845000"
                    options={{ format: 'CODE128C', ean128: true }}
                    renderer={undefined}
                  /> */}
              <Typography
                variant="body2"
                fontWeight="900"
                color={theme.palette.primary.main}
                fontSize="20px"
                textAlign="center"
              >
                Boleto Bancário
              </Typography>
            </Card>
            {!loadingPayment ? (
              <>
                <PaymentData
                  date={paym.due_date}
                  value={paym.total}
                  due_date_corR={paym.due_date_corR}
                />

                {paym.barcode_line !== '' && (
                  <Box display="flex" flexDirection="column" alignItems="center" marginTop="15px">
                    <Typography
                      variant="body2"
                      fontWeight="400"
                      color={theme.palette.grey[800]}
                      fontSize="16px"
                    >
                      Linha digitável
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="900"
                      color={theme.palette.grey[800]}
                      fontSize="16px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      {paym.barcode_line}
                    </Typography>
                    <CopyToClipboard text={paym.barcode_line}>
                      <Button
                        style={{ marginTop: 15 }}
                        aria-describedby={id}
                        onClick={handleClickPopover}
                        variant="outlined"
                        size="small"
                        startIcon={<ImCopy style={{ width: 24, height: 24 }} />}
                      >
                        Copiar
                      </Button>
                    </CopyToClipboard>
                    <Popover
                      id={id}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{ marginLeft: 5 }}
                    >
                      <Typography sx={{ p: 2 }}>Código copiado!</Typography>
                    </Popover>
                  </Box>
                )}
                <Typography
                  variant="body1"
                  fontSize={'16px'}
                  fontWeight="400"
                  color="text.primary"
                  textAlign="center"
                  marginTop="15px"
                >
                  Deseja visualizar o boleto dessa parcela?
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <Button
                    onClick={() => downloadBoleto()}
                    variant="contained"
                    startIcon={<AiOutlineCloudDownload />}
                    size="medium"
                  >
                    Visualizar Boleto
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  fontSize={'16px'}
                  fontWeight="400"
                  color="text.primary"
                  textAlign="center"
                  mt={5}
                >
                  {`Estamos processando o boleto, por favor, aguarde um instante.`}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <CircularProgress size={'15px'} />
                </div>
              </>
            )}
          </Card>
        )}
        {showPayment.pix && (
          <Card
            sx={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              margin: 2,
            }}
          >
            <Box marginBottom="20px">
              <img src="/assets/icons/pix.png" alt="PIX Logo" style={{ maxWidth: 100 }} />
            </Box>
            {!loadingPayment ? (
              <>
                <Card
                  style={{
                    padding: 15,
                    boxShadow: `0 0 1px 0`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <QRCodeSVG
                    value={paym.copy_pix.trim()}
                    size={200}
                    bgColor={'#ffffff'}
                    fgColor={'#184981'}
                    level={'L'}
                    includeMargin={false}
                  />
                </Card>
                <PaymentData
                  due_date_corR={paym.due_date_corR}
                  date={paym.due_date}
                  value={paym.total}
                />
                <Box display="flex" flexDirection="column" alignItems="center" marginTop="10px">
                  <Typography
                    variant="body2"
                    fontWeight="400"
                    color={theme.palette.grey[800]}
                    fontSize="16px"
                    marginBottom="10px"
                  >
                    PIX Copia e cola
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="900"
                    color={theme.palette.grey[800]}
                    fontSize="12px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    {paym.copy_pix.trim()}
                  </Typography>
                  <CopyToClipboard text={paym.copy_pix.trim()}>
                    <Button
                      style={{ marginTop: 15 }}
                      aria-describedby={id}
                      onClick={handleClickPopover}
                      variant="outlined"
                      size="small"
                      startIcon={<ImCopy style={{ width: 24, height: 24 }} />}
                    >
                      Copiar
                    </Button>
                  </CopyToClipboard>
                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    style={{ marginLeft: 5 }}
                  >
                    <Typography sx={{ p: 2 }} style={{ border: 1 }}>
                      Código copiado!
                    </Typography>
                  </Popover>
                </Box>
                <List sx={{ width: '100%' }}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: theme.palette.primary.lighter }}>
                        <BsPhone style={{ width: 24, height: 24 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <p>
                          Abra o app do seu banco ou instituição financeira e{' '}
                          <strong>entre no ambiente Pix</strong>
                        </p>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: theme.palette.primary.lighter }}>
                        <BsQrCode style={{ width: 24, height: 24 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <p>
                          Escolha a opção de <strong>pagar com QR Code</strong> e escanele o código
                          acima
                        </p>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: theme.palette.primary.lighter }}>
                        <AiOutlineCheckCircle style={{ width: 24, height: 24 }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Confirme as informações e finalize o pagamento" />
                  </ListItem>
                </List>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  fontSize={'16px'}
                  fontWeight="400"
                  color="text.primary"
                  textAlign="center"
                  mt={5}
                >
                  {`Estamos processando o pix, por favor, aguarde um instante.`}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <CircularProgress size={'15px'} />
                </div>
              </>
            )}
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
}

