import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';

import { UpdateDependentForm } from '../forms/UpdateDependentForm';
import { Steps } from '..';
import { PrivateModal } from 'src/sections/auth/PrivateModal';
import { useState } from 'react';
import { PrivacyModal } from 'src/sections/auth/PrivateModal/Privacity';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

interface UpdateDependentStepProps {
  changeStep: (step: Steps) => void;
}

export function UpdateDependentStep({ changeStep }: UpdateDependentStepProps) {
  const [openPrivateModal, setOpenPrivateModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  function handleOpenPrivateModal() {
    setOpenPrivateModal(true)
  }

  function handleClosePrivateModal() {
    setOpenPrivateModal(false)
  }

  function handleOpenPrivacyModal() {
    setOpenPrivacyModal(true)
  }

  function handleClosePrivacyModal() {
    setOpenPrivacyModal(false)
  }

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} textTransform={'uppercase'}>
              Etapa 2 de 2
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{ mt: 3 }}
              fontWeight="bold"
              marginBottom="20px"
            >
              Agora aproveite para atualizar os seus dados de cadastro antes de entrar no portal.
            </Typography>
          </Box>
        </Stack>
        <UpdateDependentForm changeStep={changeStep} handleOpenPrivateModal={handleOpenPrivateModal} handleOpenPrivacyModal={handleOpenPrivacyModal} />
      </ContentStyle>
      <PrivateModal open={openPrivateModal} onCloseModal={handleClosePrivateModal} />
      <PrivacyModal open={openPrivacyModal} onCloseModal={handleClosePrivacyModal} />
    </Container>
  );
}
