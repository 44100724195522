import { useState } from 'react';

import { Box, Typography, useTheme, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import ReactQuill from 'react-quill';
import axiosInstance from 'src/utils/axios';

interface Faq {
  answer: string;
  category_faq_id: string;
  id: string;
  order: number;
  question: string;
  title: string;
}

interface FaqDetailsProps {
  faq: Faq;
}

export function FaqDetails({ faq }: FaqDetailsProps) {
  const theme = useTheme();

  const [seeMore, setSeeMore] = useState(false);

  async function seeFaq() {
    setSeeMore(true);

    await axiosInstance.get(`/users/log/see/faqs/${faq.id}`);
  }

  return (
    <Box
      key={faq.id}
      sx={{
        borderRadius: '16px',
        backgroundColor: theme.palette.grey[200],
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        padding: '1rem',
      }}
    >
      <Typography
        color={theme.palette.primary.main}
        sx={{ fontWeight: 700, fontSize: '1rem', lineHeight: '1.5rem' }}
      >
        {faq.question}
      </Typography>

      {!seeMore && (
        <Button onClick={seeFaq} startIcon={<Iconify icon="bxs:down-arrow" width={8} height={8} />}>
          Ver mais
        </Button>
      )}

      <Box
        sx={{ display: seeMore ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box sx={{ width: '100%' }}>
          <ReactQuill value={faq.answer} readOnly={true} theme={'bubble'} />
        </Box>

        {seeMore && (
          <Button
            onClick={() => setSeeMore(false)}
            startIcon={<Iconify icon="bxs:up-arrow" width={8} height={8} />}
          >
            Ver menos
          </Button>
        )}
      </Box>
    </Box>
  );
}

