import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Tooltip, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import Page from 'src/components/Page';
import { LoginForm } from 'src/sections/auth/login';
import SimpleHeader from 'src/components/Header/SimpleHeader';
import { BenefitsModal } from 'src/sections/@dashboard/user/modals/BenefitsModal';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <Page title="Login">
      <RootStyle>
        <SimpleHeader />
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  fontSize={'20px'}
                  lineHeight={'30px'}
                  fontWeight={'600'}
                  marginBottom={'10px'}
                >
                  Seja bem-vindo(a) a Área do cliente!
                </Typography>
              </Box>

              <Tooltip title={method} placement="right">
                <></>
              </Tooltip>
            </Stack>

            <LoginForm handleOpenModal={handleOpen} />

            <Typography
              variant="body1"
              align="left"
              sx={{ mt: 3 }}
              fontWeight="bold"
              marginBottom="20px"
            >
              Já possui um plano contratado mas ainda não tem acesso ao portal?
            </Typography>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
              href="/auth/updateAccount"
            >
              Primeiro acesso
            </LoadingButton>
          </ContentStyle>
        </Container>
        <BenefitsModal onClose={handleClose} open={open} />
      </RootStyle>
    </Page>
  );
}
