import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import axios from 'axios';
import { IUser } from 'src/@types/auth';
import axiosInstance from './axios';
import { firebaseConfig } from 'src/firebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

export const fetchToken = async (user: IUser) => {
  await getToken(messaging, {
    vapidKey: process.env.REACT_APP_REACT_APP_VAPIDKEY,
  })
    .then(async (response) => {
      const contractResponse = await axiosInstance.get(`/users/contract/${user.id}`);
      const contracts = contractResponse.data;
      // Parametros para envio
      var params = {
        user: user,
        addToken: response,
        platform: 'WEB',
        model: null,
        appVersion: null,
        osVersion: null,
        contracts: contracts,
      };
      const panelAPIKey = process.env.REACT_APP_PANEL_API_KEY || '';

      // Cria o tolen
      await axios({
        method: 'PUT',
        url: process.env.REACT_APP_ADMIN_APP_API + '/admin/user',
        headers: {
          'x-api-key': panelAPIKey,
        },
        data: params,
      }).catch((err) => {
        console.log(
          'Ocorreu um erro ao atualizar o token do usuário. ',
          JSON.stringify(err, null, 2)
        );
        // catch error while creating client token
      });
    })
    .catch((err) => {
      console.log('Ocorreu um erro ao recuperar o token. ', JSON.stringify(err, null, 2));
      // catch error while creating client token
    });
};

export const messaging = getMessaging(firebaseApp);

