import { Container } from '@mui/material';

import useSettings from '../../hooks/useSettings';

import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function EmptyPage() {
  const { themeStretch } = useSettings();

  return (
    <Page title="General: App">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <img src="https://faculdadeuninorte.com.br/wp-content/uploads/2021/07/construcao-1024x458.png" alt="Pagina em construção" />
      </Container>
    </Page>
  );
}
