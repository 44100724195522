import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, Button } from '@mui/material';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import { useSnackbar } from 'notistack';
import { toast } from 'react-toastify';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

const TextVerify = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginBottom: '20px',
}));

// ----------------------------------------------------------------------

export default function StepTwo() {
  const { setStepOne, setStepTwo, setStepThree, typeSend, setTypeSend, currentUser } =
    useUpdateSteps();
  const { enqueueSnackbar } = useSnackbar();

  async function accessStepTwo(type: 'whatsapp' | 'sms') {
    setTypeSend(type);

    try {
      const response = await axiosInstance.post(
        `/users/confirmation/${currentUser.phone_number}?type=${type}`,
        {
          id: currentUser.id,
        }
      );

      const { code } = response.data;

      if (process.env.REACT_APP_ENABLE_TEST === 'true') {
        enqueueSnackbar(`codigo ${code}`, {
          persist: true,
        });
      }

      setStepTwo(false);
      setStepThree(true);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  }

  function goBack() {
    setStepOne(true);
    setStepTwo(false);
  }

  function callCRC() {
    window.open('https://wa.me/558440002002');
  }

  // ----------------------------------------------------------------------

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ color: 'text.secondary' }} textTransform={'uppercase'}>
              Etapa 2 de 4
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{ mt: 3 }}
              fontWeight="bold"
              marginBottom="5px"
            >
              Confirme se o número de celular abaixo é o seu contato.
            </Typography>
          </Box>
        </Stack>
        <TextVerify>{'*******' + currentUser.phone_number.slice(7)}</TextVerify>
        {/* <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => accessStepTwo('whatsapp')}
        >
          Receber código pelo WhatsApp
        </Button> */}
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={() => accessStepTwo('sms')}
          sx={{ mt: '1rem' }}
        >
          Receber código por SMS
        </Button>
        <Typography variant="body1" align="left" sx={{ mt: 3 }} fontWeight="500" marginBottom="5px">
          Não possui mais este número ou não consegue receber o código de confirmação?
        </Typography>
        <Button fullWidth size="large" variant="outlined" onClick={() => callCRC()}>
          Fale conosco
        </Button>
        <BoxButtonUpdate>
          <LoadingButton
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            style={{ marginRight: 10 }}
            onClick={() => goBack()}
          >
            <MdOutlineKeyboardArrowLeft />
            Voltar
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!currentUser.phone_number}
            onClick={() => accessStepTwo(typeSend)}
          >
            Avançar
            <MdOutlineKeyboardArrowRight />
          </LoadingButton>
        </BoxButtonUpdate>
      </ContentStyle>
    </Container>
  );
}
