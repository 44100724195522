import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';
import SimpleHeader from 'src/components/Header/SimpleHeader';
import { CheckDependentStep } from './steps/CheckDependentStep';
import { UpdateDependentStep } from './steps/UpdateDependentStep';
import { useDependentAccess } from 'src/contexts/FirstAccessDependentContext';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export type Steps = 'checkDependentStep' | 'updateDependentStep';

// ----------------------------------------------------------------------

export default function DependentAccess() {
  const { currentStep, handleStep } = useDependentAccess();

  return (
    <Page title="Atualização">
      <RootStyle>
        <>
          <SimpleHeader />

          {currentStep === 'checkDependentStep' ? (
            <CheckDependentStep key="check-dependent" changeStep={handleStep} />
          ) : (
            <UpdateDependentStep key="update-dependent" changeStep={handleStep} />
          )}
        </>
      </RootStyle>
    </Page>
  );
}
