import { Box, Card, CircularProgress, Container, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { isMobileSafari, isOpera, isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import Iconify from 'src/components/Iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import navCarrouselButton from 'src/layouts/dashboard/navbar/navCarrouselButton';
import IPlan from 'src/pages/dashboard/@types';
import axiosInstance from 'src/utils/axios';

export function CarrouselButtons() {
  const carouselRef = useRef<Slider>(null);
  const { user } = useAuth();

  const [hasOnlyJazigo, setHasOnlyJazigo] = useState(false);

  const [loadingButtonLecupon, setLoadingButtonLecupon] = useState(false);

  const navigate = useNavigate();

  const getContract = async () => {
    try {
      const response = await axiosInstance.get(`/users/contract/${user.id}`);
      const contracts = response.data;

      // Filtra apenas contratos ativos
      const activeContracts = contracts.filter((contract: IPlan) => contract.active);

      // Verifica se existe apenas um contrato ativo e se esse contrato é do tipo "JAZIGO"
      const isOnlyJazigo = activeContracts.length === 1 && activeContracts[0].type_contract === 'C';

      // Atualiza a variável de estado
      setHasOnlyJazigo(isOnlyJazigo);
    } catch (error) {
      console.error('Ocorreu um erro ao buscar os contratos:', error);
    }
  };

  useEffect(() => {
    getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    arrows: true,
    slidesToShow: 7,
    infinite: false,
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 7 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 5 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 460,
        settings: { slidesToShow: 3 },
      },
    ],
  };

  async function lecupon() {
    setLoadingButtonLecupon(true);
    try {
      const response = await axiosInstance.get(`/users/lecupon/getlecupon/${user.cpf}`);

      if (isSafari || isMobileSafari || isOpera) {
        window.location.assign(response.data.web_smart_link);
      } else {
        window.open(response.data.web_smart_link, '_blank');
      }

      setLoadingButtonLecupon(false);
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setLoadingButtonLecupon(false);
    }
  }

  function navigateTo(path: string) {
    navigate(path);
  }

  return (
    <Container component={MotionViewport} sx={{ pb: 10, textAlign: 'left', px: 0, py: 0.5 }}>
      <Box sx={{ position: 'relative' }}>
        <Slider ref={carouselRef} {...settings}>
          <Box component={m.div} variants={varFade().in} sx={{ py: 0, mx: '1px' }}>
            <Card
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100px',
                height: '100px',
                borderRadius: '1rem',
                backgroundColor: '#EA9000',
              }}
              onClick={() => lecupon()}
            >
              {loadingButtonLecupon && <CircularProgress size={14} />}
              {!loadingButtonLecupon && (
                <>
                  <Box
                    component="span"
                    sx={{
                      width: 24,
                      height: 24,
                      color: 'white',
                      display: 'inline-block',
                    }}
                  >
                    <Iconify icon="bxs:shopping-bags" fontSize={25} />
                  </Box>
                  <Typography sx={{ fontSize: '12px', textAlign: 'center', color: 'white' }}>
                    Sempre + Benefícios
                  </Typography>
                </>
              )}
            </Card>
          </Box>
          {navCarrouselButton(user.role, hasOnlyJazigo)[0].items.map((item: any) => (
            <Box
              key={item.title}
              component={m.div}
              variants={varFade().in}
              sx={{ py: 0.5, mx: '5px' }}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100px',
                  padding: item.title === 'Onde Estamos' ? '15px' : 0,
                  height: '100px',
                  borderRadius: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => navigateTo(item.path)}
              >
                <Box
                  component="span"
                  sx={{
                    width: 24,
                    height: 24,
                    color: '#7DAFE8',
                    display: 'inline-block',
                  }}
                >
                  {item.icon}
                </Box>
                <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>{item.title}</Typography>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  );
}

