import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-quill/dist/quill.snow.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './utils/highlight';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';

import { AuthProvider } from './contexts/JWTContext';

import App from './App';
import { DependentAccessProvider } from './contexts/FirstAccessDependentContext';
import { FormUpdateProvider } from './contexts/FormUpdateContext';
import { LecuponProvider } from './contexts/LecuponContext';
import { NotificationsProvider } from './contexts/NotificationContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AuthProvider>
    <FormUpdateProvider>
      <DependentAccessProvider>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <LecuponProvider>
                  <NotificationsProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </NotificationsProvider>
                </LecuponProvider>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </DependentAccessProvider>
    </FormUpdateProvider>
  </AuthProvider>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
