// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ToastContainer } from 'react-toastify';
// CSS
import 'react-toastify/dist/ReactToastify.min.css';
// Moment
import 'moment/locale/pt-br';
import moment from 'moment';
moment.locale('pt-br');

// ------------------ APP ------------------

export default function App() {
  // ------------------ JSX ------------------
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
          <ToastContainer />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

