import { m } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import { MotionViewport, varFade } from 'src/components/animate';
import axiosInstance from 'src/utils/axios';
import CardNews from './CardNews';
import LoadingScreen from 'src/components/LoadingScreen';

var listNews: any = [];

export default function CarrouselNews() {
  const carouselRef = useRef<Slider>(null);

  const [removeLoading, setRemoveLoading] = useState(false);

  const theme = useTheme();

  const settings = {
    arrows: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '0px',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const getNews = async () => {
    listNews = [];
    var { data } = await axiosInstance.get('/users/news/list');

    data.map((i: any) => listNews.push(i));
    setRemoveLoading(true);
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      {/* <Divider sx={{ margin: '25px 0px' }} /> */}
      {/* <Typography
        variant="h6"
        fontWeight={600}
        fontSize="18px"
        color="black"
        textAlign="left"
        marginBottom="0"
      >
        Confira todas as novidades
      </Typography> */}
      {removeLoading ? (
        <Container component={MotionViewport} sx={{ pb: 10, textAlign: 'left' }}>
          <Box sx={{ position: 'relative' }}>
            <Slider ref={carouselRef} {...settings}>
              {listNews.map((i: any) => (
                <Box key={i.id} component={m.div} variants={varFade().in} sx={{ px: 0.5, py: 0 }}>
                  <CardNews
                    title={i.title}
                    description={i.description}
                    image={i.image}
                    link={i.link}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
