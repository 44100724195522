export interface IBenefitItem {
  comp: string;
  title: string;
  icon?: string;
  description?: string;
}

export interface ICustomBenefitGroup {
  icon: string;
  group: string;
  description: string;
  items: IBenefitItem[];
}

interface BenefitMapping {
  defaultProduct: { [comp: string]: IBenefitItem };
  customProduct: { [group: string]: ICustomBenefitGroup };
}

export const benefitMapping: BenefitMapping = {
  defaultProduct: {
    // NATAL, CAICÓ, CATOLÉ, SFI e JOÃO PESSOA
    '000054': {
      comp: '000054',
      title: 'Paramentação',
      icon: 'mdi:candelabra-fire',
      description:
        'Composta por 02 (duas) banquetas para apoio da Urna, 02 (dois) castiçais com velas e 01 (um) cristo ou salmo, tudo de acordo com a religião escolhida pela família',
    },
    '000055': {
      comp: '000055',
      title: 'Higienização',
      icon: 'uil:raindrops-alt',
      description: 'Higienização e preparação do ente querido para o velório',
    },
    '000058': {
      comp: '000058',
      title: 'Ornamentação do Corpo com Flor',
      icon: 'fa6-solid:gears',
      description:
        'Ornamentação do ente querido com, por exemplo, flores ou cetim, de acordo com a disponibilidade',
    },
    '000059': {
      comp: '000059',
      title: 'Vestimenta',
      icon: 'icon-park-outline:clothes-cardigan',
      description: 'Manto para vestir o ente querido, conhecida popularmente por mortalha',
    },
    '000071': {
      comp: '000079',
      title: 'Tanatopraxia',
      icon: 'healthicons:ppe-gloves-outline',
      description: 'Serviço de Tanatopraxia',
    },
    '000079': {
      comp: '000079',
      title: 'Velório Virtual',
      icon: 'mdi:virtual-meeting',
      description:
        'Memorial virtual e transmissão de cerimônia de despedida ao vivo na plataforma personalizada Memória Viva (serviço exclusivo para unidades Sempre, a depender da disponibilidade).',
    },
    '001242': {
      comp: '001242',
      title: 'Livro de Presença',
      icon: 'icon-park-outline:bookmark-three',
      description:
        'Livro de presença padrão PLANO SEMPRE para assinatura dos visitantes, entregue à família ao final do velório',
    },
    '009927': {
      comp: '009927',
      title: 'Orientação de Serviço de Cartório',
      icon: 'fluent:clipboard-text-edit-32-regular',
      description: 'Orientação de Serviço de Cartório',
    },
    // NATAL, CAICÓ, CATOLE, SFI
    '919008': {
      comp: '919008',
      title: 'Clube de Benefícios Sempre',
      icon: 'streamline:give-gift',
      description: 'Clube de Benefícios Sempre',
    },
    '919013': {
      comp: '919013',
      title: 'Kit Café (Externo)',
      icon: 'ep:coffee-cup',
      description:
        'Composto minimamente por açúcar, café, chá, água, copos descartáveis e bolachas, a depender da disponibilidade',
    },
    // CAICÓ, CATOLÉ, SFI
    '009925': {
      comp: '009925',
      title: 'Sala de Velório - Sempre',
      icon: 'map:funeral-home',
      description: 'Aluguel de sala de cerimônia de despedida padrão unidades Sempre',
    },
    // JOÃO PESSOA
    '918831': {
      comp: '918831',
      title: 'Kit Café (Externo)',
      icon: 'ep:coffee-cup',
      description:
        'Composto minimamente por açúcar, café, chá, água, copos descartáveis e bolachas, a depender da disponibilidade',
    },
    '918832': {
      comp: '918832',
      title: 'Clube de Benefícios Sempre',
      icon: 'streamline:give-gift',
      description: 'Clube de Benefícios Sempre',
    },

    // PRODUTOS ADICIONAIS (DE ASSINATURA)

    // Velório
    // NATAL e JOÃO PESSOA
    // '009925': {
    //   comp: '009925',
    //   title: 'Sala de Velório',
    //   icon: 'map:funeral-home',
    //   description: 'Sala de Velórios (Assinatura)',
    // },

    // NATAL
    '919010': {
      comp: '919010',
      title: 'Velório - Vila Memorial',
      icon: 'map:funeral-home',
      description: 'Velório - Vila Memorial (Assinatura)',
    },

    // Missa de Corpo Presente
    // JOÃO PESSOA
    '918963': {
      comp: '918963',
      title: 'Missa de Corpo Presente',
      icon: 'lucide:church',
      description: 'Missa de Corpo Presente (Assinatura)',
    },
    // NATAL, CAICÓ, CATOLÉ e SFI
    '919001': {
      comp: '919001',
      title: 'Missa de Corpo Presente',
      icon: 'lucide:church',
      description: 'Missa de Corpo Presente (Assinatura)',
    },

    // Cerimônia Musical
    // JOÃO PESSOA
    '918964': {
      comp: '918964',
      title: 'Cerimônia Musical',
      icon: 'fluent-emoji-high-contrast:musical-notes',
      description: 'Cerimônia Musical (Assinatura)',
    },
    // NATAL, CAICÓ, CATOLÉ e SFI
    '918999': {
      comp: '918999',
      title: 'Cerimônia Musical',
      icon: 'fluent-emoji-high-contrast:musical-notes',
      description: 'Cerimônia Musical (Assinatura)',
    },

    // Cremação Humana
    // JOÃO PESSOA
    '918965': {
      comp: '918965',
      title: 'Cremação Humana Uso Futuro',
      icon: 'fluent:fireplace-20-filled',
      description: 'Cremação Humana Uso Futuro (Assinatura)',
    },
    // NATAL, CAICÓ, CATOLÉ e SFI
    '919004': {
      comp: '919004',
      title: 'Cremação Humana Uso Futuro',
      icon: 'fluent:fireplace-20-filled',
      description: 'Cremação Humana Uso Futuro (Assinatura)',
    },

    // Cremação Pet
    // JOÃO PESSOA
    '918966': {
      comp: '918966',
      title: 'Cremação Pet Uso Futuro',
      icon: 'fluent:fireplace-20-filled',
      description: 'Cremação Pet Uso Futuro (Assinatura)',
    },
    // NATAL, CAICÓ, CATOLÉ e SFI
    '919006': {
      comp: '919006',
      title: 'Cremação Pet Uso Futuro',
      icon: 'fluent:fireplace-20-filled',
      description: 'Cremação Pet Uso Futuro (Assinatura)',
    },

    // ADITIVO ÚNICO
    'sempre-unico-aditivo': {
      comp: 'sempre-unico-aditivo',
      title: 'Sempre Único - Aditivo',
      icon: 'mdi:star',
      description: 'Benefício aditivo exclusivo',
    },
  },
  customProduct: {
    'Urna*': {
      icon: 'emojione-monotone:funeral-urn',
      group: 'Urna*',
      description:
        'Caixão/Ataúde, de acordo com o portfólio praticado pelo PLANO SEMPRE à época da solicitação dos serviços.',
      items: [
        // Urna Especial
        // JOÃO PESSOA
        {
          comp: '918834',
          title: 'Urna 700 Bíblia/Cristo Plano',
        },
        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '919011',
          title: 'Urna 700 Bíblia/Cristo Plano',
        },
        // Urna Especial
        // JOÃO PESSOA
        {
          comp: '918972',
          title: 'Urna Essencial',
        },
        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '919002',
          title: 'Urna Essencial',
        },
        // Urna Especial
        // JOÃO PESSOA
        {
          comp: '918973',
          title: 'Urna Especial',
        },
        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '918998',
          title: 'Urna Especial',
        },
      ],
    },
    'Coroa de Flores*': {
      icon: 'mdi:flower-tulip',
      group: 'Coroa de Flores*',
      description:
        '01 (uma) coroa de flores naturais ou artificiais, cuja escolha ficará a exclusivo critério do PLANO SEMPRE (de acordo com a diferenciação estipulada pelo portfólio vigente à época da solicitação do serviço).',
      items: [
        // Coroa Pequena
        // JOÃO PESSOA
        {
          comp: '918830',
          title: 'Coroa Pequena',
        },

        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '919012',
          title: 'Coroa Pequena',
        },

        // Coroa Média
        // JOÃO PESSOA
        {
          comp: '918970',
          title: 'Coroa Média',
        },
        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '919007',
          title: 'Coroa Média',
        },

        // Coroa Grande
        // JOÃO PESSOA
        {
          comp: '918971',
          title: 'Coroa Grande',
        },
        // NATAL, CAICÓ, CATOLÉ e SFI
        {
          comp: '919005',
          title: 'Coroa Grande',
        },
      ],
    },
    'Transporte do seu Ente Querido*': {
      icon: 'mdi:car-convertible',
      group: 'Transporte do seu Ente Querido*',
      description:
        'Remoção e cortejo do ente querido sem ônus, só por vias terrestres, considerando: transporte do ente do local do óbito para o local onde serão realizados velório e cortejo para sepultamento/cremação, configurando-se um deslocamento intermunicipal ou interestadual.',
      items: [
        // Translado Terrestre
        // NATAL, CAICÓ, CATOLÉ, SFI e JOÃO PESSOA
        {
          comp: '910467',
          title: 'Traslado Terrestre',
        },

        // Translado 800 km
        // JOÃO PESSOA
        {
          comp: '918968',
          title: 'Traslado 800 km',
        },
        // NATAL, CAICÓ, CATOLÉ, SFI
        {
          comp: '918997',
          title: 'Traslado 800 km',
        },

        // Translado 1200 km
        // JOÃO PESSOA
        {
          comp: '918969',
          title: 'Traslado 1200 km',
        },

        // NATAL, CAICÓ, CATOLÉ, SFI
        {
          comp: '919000',
          title: 'Traslado 1200 km',
        },
      ],
    },
    'Kit Café**': {
      icon: 'ep:coffee-cup',
      group: 'Kit Café**',
      description: 'Kit Lanche (Assinatura)',
      items: [
        // Kit Café
        // NATAL, CAICÓ, CATOLÉ, SFI e JOÃO PESSOA
        {
          comp: '000066',
          title: 'Kit Café',
        },
        // Kit Lanche
        // JOÃO PESSOA
        {
          comp: '918967',
          title: 'Kit Lanche',
        },

        // NATAL, CAICÓ, CATOLÉ, SFI e JOÃO PESSOA
        {
          comp: '919003',
          title: 'Kit Lanche',
        },
      ],
    },
  },
};

