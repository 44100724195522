import { useContext } from 'react';
import { LecuponContext } from 'src/contexts/LecuponContext';

export const useLecupon = () => {
  const context = useContext(LecuponContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};
