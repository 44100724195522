import { Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Logo from 'src/components/Logo';
import SocialsButton from 'src/components/SocialsButton';
import useAuth from 'src/hooks/useAuth';
import IPlan from 'src/pages/dashboard/@types';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: '25px 0 0',
  bottom: 0,
  backgroundColor: theme.palette.grey[100],
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const theme = useTheme();
  const { user } = useAuth();
  const [hasOnlyJazigo, setHasOnlyJazigo] = useState(false);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        const { data } = await axiosInstance.get(`/users/contract/${user.id}`);
        const activeContracts = data.filter((contract: IPlan) => contract.active);
        const isOnlyJazigo =
          activeContracts.length === 1 && activeContracts[0].type_contract === 'C';
        setHasOnlyJazigo(isOnlyJazigo);
      } catch (error) {
        console.error('Error fetching contracts: ', error);
      }
    };

    fetchContractData();
  }, [user.id]);

  // LINKS

  const LINKS = [
    {
      headline: 'plataforma',
      children: [
        {
          name: 'Meus Dependentes',
          href: PATH_DASHBOARD.user.dependents,
          targetBlank: false,
          lead: false,
          dependent: false,
          proposal_user: true,
          visible: true,
        },
        {
          name: 'Histórico Financeiro',
          href: PATH_DASHBOARD.general.historic,
          targetBlank: false,
          lead: false,
          dependent: true,
          proposal_user: false,
          visible: true,
        },
        {
          name: 'Contrato do plano',
          href: PATH_DASHBOARD.general.contract,
          targetBlank: false,
          lead: false,
          dependent: false,
          proposal_user: true,
          visible: true,
        },
        {
          name: 'Carteirinha digital',
          href: PATH_DASHBOARD.general.card,
          targetBlank: false,
          lead: false,
          dependent: true,
          proposal_user: false,
          visible: !hasOnlyJazigo,
        },
        {
          name: 'Onde estamos',
          href: PATH_DASHBOARD.general.serviceUnits,
          targetBlank: false,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
      ],
    },
    {
      headline: 'Legal',
      children: [
        {
          name: 'Política de Privacidade',
          href: 'https://planosempre.com.br/politica-de-privacidade',
          targetBlank: true,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
        {
          name: 'Política de Uso',
          href: '/dashboard/userPolicy',
          targetBlank: false,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
        // {
        //   name: 'Política de Cookies', href: '#', targetBlank: false,
        //   lead: true, dependent: true
        // },
      ],
    },
    {
      headline: 'Contato',
      children: [
        {
          name: 'contato@irmaosvila.com.br',
          href: 'mailto:contato@irmaosvila.com.br?Subject=SOBRE%20O%20PLANO%20SEMPRE',
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
        {
          name: '84 4000-2002 (Whatsapp)',
          href: 'https://api.whatsapp.com/send?phone=558440002002',
          targetBlank: true,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
        {
          name: '4000-2002 (capitais e regiões metropolitanas)',
          href: 'tel:40002002',
          targetBlank: false,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
        {
          name: '0800 402 2002 (demais localidades)',
          href: 'tel:08004022002',
          targetBlank: false,
          lead: true,
          dependent: true,
          proposal_user: true,
          visible: true,
        },
      ],
    },
  ];

  return (
    <RootStyle>
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'left', md: 'space-between' }}
          sx={{ textAlign: { xs: 'left', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { md: 'inherit' } }} />
          </Grid>

          <Grid item md={3}>
            <Typography variant="body2">
              Amparo e cuidado são sinônimos dessa marca que está há mais de 18 anos no mercado,
              protegendo mais de 700 mil vidas, em 200 cidades entre o Rio Grande do Norte e
              Paraíba. O sempre é mais que assistência funeral, é assistência familiar + benefícios
              que valem a pena!
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'left', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" color={theme.palette.grey[600]}>
                    {list.headline}
                  </Typography>
                  {user.role === 'USER' &&
                    list.children
                      .filter((link) => link.visible)
                      .map((link) => (
                        <Link
                          href={link.href}
                          target={link.targetBlank ? '_blank' : '_self'}
                          key={link.name}
                          color={theme.palette.primary.main}
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ))}
                  {user.role === 'DEPENDENT' &&
                    list.children
                      .filter((i) => i.dependent === true)
                      .map((link) => (
                        <Link
                          href={link.href}
                          target={link.targetBlank ? '_blank' : '_self'}
                          key={link.name}
                          color={theme.palette.primary.main}
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ))}
                  {user.role === 'LEAD' &&
                    list.children
                      .filter((i) => i.lead === true)
                      .map((link) => (
                        <Link
                          href={link.href}
                          target={link.targetBlank ? '_blank' : '_self'}
                          key={link.name}
                          color={theme.palette.primary.main}
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ))}
                  {user.role === 'PROPOSAL_USER' &&
                    list.children
                      .filter((i) => i.proposal_user === true)
                      .map((link) => (
                        <Link
                          href={link.href}
                          target={link.targetBlank ? '_blank' : '_self'}
                          key={link.name}
                          color={theme.palette.primary.main}
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'left', md: 'left' },
          }}
        >
          Sempre © 2023 | Todos os direitos reservados.
        </Typography>
      </Container>
    </RootStyle>
  );
}
