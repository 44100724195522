import { useEffect, useState } from 'react';

import {
  Container,
  Divider,
  Box,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
} from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import { FaqDetails } from 'src/sections/@dashboard/faq/FaqDetails';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';

interface IFaq {
  answer: string;
  category_faq_id: string;
  id: string;
  order: number;
  question: string;
  title: string;
}

interface Category {
  id: string;
  order: number;
  category_name: string;
  faq: IFaq[];
  presignedUrl: string | null;
}

export function Faq() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const [categories, setCategories] = useState<Category[]>([]);

  async function getCategories() {
    const response = await axiosInstance.get<Category[]>(
      `/users/faq/categories?filter=${user.role === 'LEAD' ? 'Lead' : 'Cliente'}`
    );

    setCategories(response.data);
  }

  async function callCrc() {
    try {
      const a = document.createElement('a');
      a.href = 'tel:8440002002';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  async function callCrcWhatsapp() {
    try {
      const a = document.createElement('a');
      a.href = 'https://wa.me/558440002002';
      a.target = '_blank';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Dúvidas Frequentes">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
          heading="Dúvidas Frequentes"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Dúvidas Frequentes' }]}
        />

        <Divider sx={{ marginBottom: '20px' }} />

        <Box>
          <Typography
            variant="h6"
            color={theme.palette.grey[800]}
            sx={{
              fontWeight: 600,
              fontSize: '1.12rem',
              lineHeight: '1.75rem',
              marginBottom: '0.75rem',
            }}
          >
            Dúvidas frequentes
          </Typography>

          <Typography
            color={theme.palette.grey[600]}
            sx={{
              fontWeight: 400,
              fontSize: '0.75rem',
              lineHeight: '1.25rem',
            }}
          >
            Em um momento tão delicado como a perda de um ente querido, muitas vezes ficamos sem
            saber quais providências devemos tomar. Nós entendemos você! É por isso que o Plano
            Sempre está pronto para lhe ajudar. Tire todas as dúvidas e saiba o que fazer na hora do
            falecimento de um ente querido.
          </Typography>
        </Box>

        <Box sx={{ marginTop: '1.5rem' }}>
          {categories
            .filter((category) => category.faq.length > 0)
            .map((category) => (
              <Accordion
                key={category.id}
                sx={{
                  '&:before': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <AccordionSummary
                  sx={{ border: 'none', display: 'flex', alignItems: 'center' }}
                  expandIcon={<Iconify icon="ep:arrow-down-bold" />}
                >
                  <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    {category.presignedUrl ? (
                      <img
                        src={category.presignedUrl}
                        alt=""
                        style={{ width: '40px', height: '40px', borderRadius: '8px' }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '40px',
                          height: '40px',
                          background: '#184981',
                          borderRadius: '8px',
                        }}
                      />
                    )}
                    <Typography
                      color={theme.palette.primary.main}
                      sx={{ marginLeft: '1.5rem', display: 'flex', alignItems: 'center' }}
                    >
                      {category.category_name}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {category.faq.map((faq) => (
                    <FaqDetails key={faq.id} faq={faq} />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>

        <Card
          sx={{
            width: '100%',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src="/assets/illustrations/headset.png" alt="entre em contato" />
            <Box sx={{ paddingLeft: '1.5rem' }}>
              <Typography color={theme.palette.secondary.main}>Precisa de ajuda?</Typography>
              <Typography>
                Você pode falar diretamente com a nossa central de atendimento ao cliente.
              </Typography>
            </Box>
          </Box>

          <Button
            fullWidth
            variant="contained"
            sx={{ background: theme.palette.secondary.main, marginTop: '1rem', maxWidth: '400px' }}
            onClick={callCrcWhatsapp}
          >
            Fale conosco pelo whatsapp
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ marginTop: '1rem', maxWidth: '400px' }}
            onClick={callCrc}
          >
            Fale conosco pelo telefone
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
