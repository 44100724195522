// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    dependents: path(ROOTS_DASHBOARD, '/dependents'),
    faq: path(ROOTS_DASHBOARD, '/faq'),
    historic: path(ROOTS_DASHBOARD, '/historic'),
    contract: path(ROOTS_DASHBOARD, '/contract'),
    card: path(ROOTS_DASHBOARD, '/card'),
    serviceUnits: path(ROOTS_DASHBOARD, '/serviceUnits'),
    benefits: path(ROOTS_DASHBOARD, '/benefits'),
    cac: path(ROOTS_DASHBOARD, '/cac'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    dependents: path(ROOTS_DASHBOARD, '/user/dependents'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    settings: path(ROOTS_DASHBOARD, '/user/settings'),
    userPolicy: path(ROOTS_DASHBOARD, '/userPolicy'),
    notifications: path(ROOTS_DASHBOARD, '/user/notifications'),
  },
};
