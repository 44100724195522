import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  tomanager: getIcon('toManage'),
  historic: getIcon('historic'),
  contract: getIcon('contract'),
  card: getIcon('card'),
  serviceUnits: getIcon('serviceUnits'),
  benefits: getIcon('benefits'),
  attendance: getIcon('attendance'),
  settings: getIcon('settings'),
  faq: getIcon('faq'),
};

function navCarrouselButton(
  role: 'USER' | 'DEPENDENT' | 'LEAD' | 'PROPOSAL_USER',
  hasOnlyJazigo: boolean
) {
  if (role === 'USER') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Meus Dependentes',
            path: PATH_DASHBOARD.user.dependents,
            icon: ICONS.tomanager,
          },
          {
            title: 'Histórico Financeiro',
            path: PATH_DASHBOARD.general.historic,
            icon: ICONS.historic,
          },
          {
            title: 'Meu Plano Sempre',
            path: PATH_DASHBOARD.general.contract,
            icon: ICONS.contract,
          },
          ...(hasOnlyJazigo
            ? []
            : [
                {
                  title: 'Carteira Digital Sempre',
                  path: PATH_DASHBOARD.general.card,
                  icon: ICONS.card,
                },
              ]),
          {
            title: 'Onde Estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },

          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
        ],
      },
    ];
  }

  if (role === 'DEPENDENT') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Histórico Financeiro',
            path: PATH_DASHBOARD.general.historic,
            icon: ICONS.historic,
          },
          ...(hasOnlyJazigo
            ? []
            : [
                {
                  title: 'Carteira Digital Sempre',
                  path: PATH_DASHBOARD.general.card,
                  icon: ICONS.card,
                },
              ]),
          {
            title: 'Onde Estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
        ],
      },
    ];
  }

  if (role === 'LEAD') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Onde Estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
        ],
      },
    ];
  }

  if (role === 'PROPOSAL_USER') {
    return [
      {
        subheader: 'gerenciamento',
        items: [
          {
            title: 'Meus Dependentes',
            path: PATH_DASHBOARD.user.dependents,
            icon: ICONS.tomanager,
          },
          {
            title: 'Meu Plano Sempre',
            path: PATH_DASHBOARD.general.contract,
            icon: ICONS.contract,
          },
          {
            title: 'Onde Estamos',
            path: PATH_DASHBOARD.general.serviceUnits,
            icon: ICONS.serviceUnits,
          },
          {
            title: 'Dúvidas Frequentes',
            path: PATH_DASHBOARD.general.faq,
            icon: ICONS.faq,
          },
        ],
      },
    ];
  }

  return [];
}

export default navCarrouselButton;
