import { Dialog, DialogContent, IconButton, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';

interface PrivateModalProps {
  onCloseModal: () => void;
  open: boolean;
}

export function PrivateModal({ onCloseModal, open }: PrivateModalProps) {
  return (
    <Dialog onClose={onCloseModal} open={open}>
      <DialogContent>
        <IconButton
          sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
          onClick={onCloseModal}
        >
          <Iconify icon="ep:close" />
        </IconButton>
        <div style={{ marginTop: '1rem' }}>
          <section>
            <div>
              <strong>POLÍTICA DE USO DA ÁREA DO CLIENTE SEMPRE</strong>
            </div>

            <p style={{ marginTop: '1rem' }}>
              Ao realizar o acesso a <strong>Área do Cliente</strong>, o Usuário se submeterá
              automaticamente às regras e condições estabelecidas nesta Política de Uso.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O Usuário deverá acessar a <strong>Área do Cliente</strong> através dos dados
              fornecidos pelo PLANO SEMPRE, sendo vedado o compartilhamento de seus dados de acesso
              a terceiros e a utilização dos dados de outro usuário.
            </p>

            <p style={{ marginTop: '1rem' }}>
              Usuários menores de 18 anos ou que necessitem de representação, na forma da lei,
              deverão realizar o acesso a <strong>Área do Cliente</strong> com a assistência dos
              pais ou de seus representantes legais.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O <strong>PLANO SEMPRE</strong> poderá cancelar ou suspender temporariamente a{' '}
              <strong>Área do Cliente</strong>, bem como alterar, incluir ou excluir seus conteúdos
              e funcionalidades, a qualquer momento, independentemente de prévio aviso ao Usuário.
              Poderá, igualmente, a qualquer tempo, modificar esta Política de Uso, disponibilizando
              a versão mais recente para consulta.
            </p>

            <p style={{ marginTop: '1rem' }}>
              Toda e qualquer inserção ou alteração de dados ou outra ação executada pelo Usuário
              durante o uso da <strong>Área do Cliente</strong> será de sua exclusiva e integral
              responsabilidade, estando isento o <strong>PLANO SEMPRE</strong> de quaisquer
              reclamações, prejuízos ou perdas e danos em decorrência de tais ações ou
              manifestações.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O <strong>PLANO SEMPRE</strong> poderá bloquear e cancelar o acesso a{' '}
              <strong>Área do Cliente</strong> quando verificar que o Usuário praticou alguma
              conduta que viole o ordenamento jurídico brasileiro ou contrarie as regras desta
              Política de Uso.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O <strong>PLANO SEMPRE</strong> se exime de toda e qualquer responsabilidade pelos
              danos e prejuízos de qualquer natureza que possam decorrer do acesso, interceptação,
              eliminação, alteração, modificação ou manipulação, por terceiros autorizados ou não,
              dos dados do usuário durante a utilização da <strong>Área do Cliente</strong>.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O usuário autoriza o <strong>PLANO SEMPRE</strong>, ou terceiros indicados por este, a
              realizar o tratamento dos dados pessoais fornecidos durante o uso da{' '}
              <strong>Área do Cliente</strong>, por prazo indeterminado, para fins de implemento do
              clube de benefícios e dos serviços funerários contratados, bem como para fins de envio
              de avisos, materiais publicitários e newsletters, entre outros.
            </p>

            <p style={{ marginTop: '1rem' }}>
              O <strong>ENCARREGADO</strong> indicado pelo <strong>PLANO SEMPRE</strong> para atuar
              como canal de comunicação em temas voltados para dados pessoais, poderá ser contatado
              da seguinte forma:{' '}
            </p>

            <p style={{ marginTop: '1rem' }}>
              <strong>(DPO – DATA PROTECTION OFFICER)</strong> <br />
              <strong>[0800 402 2002] - [dpo@empresavila.com.br]</strong> <br />
              Para maiores informações, consulte nossa política de privacidade de dados disponível
              no site: https://planosempre.com.br/politica-de-privacidade/
            </p>
            <p style={{ marginTop: '1rem' }}>
              <b>Atualização: </b>
              <b>30/10/2022</b>
            </p>
            <Button variant="contained" onClick={onCloseModal} sx={{ mt: '1rem' }}>
              Fechar
            </Button>
          </section>
        </div>
      </DialogContent>
    </Dialog>
  );
}
