import { ElementType, Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { ManageDependents } from 'src/pages/ManageDependents';
import { UserPolicy } from 'src/pages/UserPolicy';
import { LoginLead } from 'src/pages/auth/LoginLead';
import { RegisterLead } from 'src/pages/auth/RegisterLead';
import DependentAccess from 'src/pages/auth/dependentAccess';
import UpdatePassword from 'src/pages/auth/updateAccount/UpdatePassword';
import UpdateAccount from 'src/pages/auth/updateAccount/updateAccount';
import { DigitalCard } from 'src/pages/dashboard/DigitalCard';
import EmptyPage from 'src/pages/dashboard/EmptyPage';
import { Faq } from 'src/pages/dashboard/Faq';
import GeneralApp from 'src/pages/dashboard/GeneralApp';
import { HistoricPayments } from 'src/pages/dashboard/HistoricPayments';
import { Notifications } from 'src/pages/dashboard/Notifications';
import { PlanContrat } from 'src/pages/dashboard/PlanContract';
import { Units } from 'src/pages/dashboard/Units';
import UserSettings from 'src/pages/dashboard/UserSettings';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import useAuth from '../hooks/useAuth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register-lead',
          element: <RegisterLead />,
        },
        {
          path: 'lead-login',
          element: (
            <GuestGuard>
              <LoginLead />
            </GuestGuard>
          ),
        },
        { path: 'updateAccount', element: <UpdateAccount /> },
        { path: 'updateAccount/new-password/*', element: <UpdatePassword /> },
        { path: 'updateDependentAccount/*', element: <DependentAccess /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'usePolicy', element: <UserPolicy /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'dependents',
          element: <EmptyPage />,
        },
        {
          path: 'historic',
          element: <HistoricPayments />,
        },
        {
          path: 'contract',
          element: <PlanContrat />,
        },
        {
          path: 'card',
          element: <DigitalCard />,
        },
        {
          path: 'serviceUnits',
          element: <Units />,
        },
        {
          path: 'benefits',
          element: <EmptyPage />,
        },
        {
          path: 'faq',
          element: <Faq />,
        },
        {
          path: 'userPolicy',
          element: <UserPolicy />,
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'settings', element: <UserSettings /> },
            {
              path: 'dependents',
              element: <ManageDependents />,
            },
            {
              path: 'notifications',
              element: <Notifications />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [{ element: <HomePage />, index: true }],
    },
    // {
    //   path: '/lead',
    //   children: [{ element: <HomeLead />, index: true }],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// TEST RENDER PAGE BY ROLE
//const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
