import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Divider, Button, Card } from '@mui/material';
import axiosInstance from 'src/utils/axios';
import LoadingScreen from 'src/components/LoadingScreen';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';

let listPlans: any[] = [];

export default function CardPlan() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [removeLoading, setRemoveLoading] = useState(false);
  const theme = useTheme();

  const getContract = async () => {
    listPlans = [];
    var { data } = await axiosInstance.get(`/users/contract/${user.id}`);

    data.map((i: any) => listPlans.push(i));
    setRemoveLoading(true);
  };

  useEffect(() => {
    getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Divider sx={{ margin: '25px 0px' }} />
      <Typography
        variant="h6"
        fontWeight={600}
        fontSize="18px"
        color="black"
        textAlign="left"
        marginBottom="0"
        mb="20px"
      >
        Plano de assinatura contratado
      </Typography>
      {removeLoading ? (
        listPlans.map((item) => (
          <Card key={item.id} id="contract" sx={{ marginBottom: '5px' }}>
            <Typography
              variant="h5"
              color={theme.palette.primary.light}
              textTransform="capitalize"
              fontSize="20"
              lineHeight="18px"
              letterSpacing="1.2px"
              margin="20px"
            >
              {item.name}
            </Typography>
            <Typography variant="h6" color={'#000'} fontSize="18px" lineHeight="18px" margin="20px">
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                item.monthly_payment
              )}
              / mês
            </Typography>

            <Button
              size="large"
              style={{
                margin: '0 auto 30px',
                paddingRight: 0,
                display: 'flex',
                alignItems: 'center',
                maxWidth: '400px',
              }}
              onClick={() => navigate(PATH_DASHBOARD.general.contract)}
            >
              Acessar Meu Plano Sempre
              <Iconify icon="ic:sharp-launch" margin="15px" />
            </Button>
          </Card>
        ))
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

