import Dialog from '@mui/material/Dialog';

import { IoCloseOutline } from 'react-icons/io5';
import { Typography, DialogContent, Box, Button } from '@mui/material';

//import { PATH_DASHBOARD } from 'src/routes/paths';

export interface FormDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ModalDuvidas(props: FormDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };
  async function callCrc() {
    try {
      const a = document.createElement('a');
      a.href = 'tel:8440002002';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  async function callCrcWhatsapp() {
    try {
      const a = document.createElement('a');
      a.href = 'https://wa.me/558440002002';
      a.target = '_blank';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent sx={{ maxWidth: '340px', padding: '0px' }}>
        <div
          style={{
            display: 'flex',
            margin: '20px 20px 0',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <IoCloseOutline size={24} onClick={handleClose} style={{ cursor: 'pointer' }} />
        </div>

        <Box sx={{ width: '100%', padding: '20px' }}>
          <Typography variant="body1" fontSize={'16px'} fontWeight="600">
            Possui alguma dúvida ou está com alguma dificuldade em acessar o portal?
          </Typography>
          <Typography margin="20px 0" variant="body1" fontSize={'16px'} fontWeight="400">
            Entre em contato com uma de nossas consultoras, através do WhatsApp ou por meio de uma
            ligação.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{ width: '100%', marginBottom: '20px' }}
              onClick={() => callCrcWhatsapp()}
            >
              Fale conosco pelo WhatsApp
            </Button>
            <Button sx={{ width: '100%', marginBottom: '20px' }} onClick={() => callCrc()}>
              Fale conosco por ligação
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

