import { Container, Divider, Link, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentInfo = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  flexWrap: 'wrap',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  borderRadius: '16px',
  padding: '24px 24px',
  mt: '1rem',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

const Titile = styled('div')(() => ({
  textAlign: 'center',
  width: '100%',
  marginBottom: '10px',
}));

const Text = styled(Typography)(() => ({
  textAlign: 'justify',
  marginBottom: '10px',
}));

// ----------------------------------------------------------------------

export function UserPolicy() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <RootStyle>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
            heading={`POLÍTICA DE USO`}
            links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'POLÍTICA DE USO' }]}
          />
        </div>
        <Divider sx={{ marginBottom: '20px' }} />
        <ContentStyle>
          <ContentInfo>
            <Titile>
              <Typography variant="h6">POLÍTICA DE USO DA ÁREA DO CLIENTE SEMPRE</Typography>
            </Titile>
            <Text variant="body2">
              Ao realizar o acesso a <strong>Área do Cliente</strong>, o Usuário se submeterá
              automaticamente às regras e condições estabelecidas nesta Política de Uso.
            </Text>
            <Text variant="body2">
              O Usuário deverá acessar a <strong>Área do Cliente</strong> através dos dados
              fornecidos pelo <strong>PLANO SEMPRE</strong>, sendo vedado o compartilhamento de seus
              dados de acesso a terceiros e a utilização dos dados de outro usuário.
            </Text>
            <Text variant="body2">
              Usuários menores de 18 anos ou que necessitem de representação, na forma da lei,
              deverão realizar o acesso a <strong>Área do Cliente</strong> com a assistência dos
              pais ou de seus representantes legais.
            </Text>
            <Text variant="body2">
              O <strong>PLANO SEMPRE</strong> poderá cancelar ou suspender temporariamente a{' '}
              <strong>Área do Cliente</strong>, bem como alterar, incluir ou excluir seus conteúdos
              e funcionalidades, a qualquer momento, independentemente de prévio aviso ao Usuário.
              Poderá, igualmente, a qualquer tempo, modificar esta Política de Uso, disponibilizando
              a versão mais recente para consulta.
            </Text>
            <Text variant="body2">
              Toda e qualquer inserção ou alteração de dados ou outra ação executada pelo Usuário
              durante o uso da <strong>Área do Cliente</strong> será de sua exclusiva e integral
              responsabilidade, estando isento o <strong>PLANO SEMPRE</strong> de quaisquer
              reclamações, prejuízos ou perdas e danos em decorrência de tais ações ou
              manifestações.
            </Text>
            <Text variant="body2">
              O <strong>PLANO SEMPRE</strong> poderá bloquear e cancelar o acesso a{' '}
              <strong>Área do Cliente</strong> quando verificar que o Usuário praticou alguma
              conduta que viole o ordenamento jurídico brasileiro ou contrarie as regras desta
              Política de Uso.
            </Text>
            <Text variant="body2">
              O <strong>PLANO SEMPRE</strong> se exime de toda e qualquer responsabilidade pelos
              danos e prejuízos de qualquer natureza que possam decorrer do acesso, interceptação,
              eliminação, alteração, modificação ou manipulação, por terceiros autorizados ou não,
              dos dados do usuário durante a utilização da <strong>Área do Cliente</strong>.
            </Text>
            <Text variant="body2">
              O usuário autoriza o <strong>PLANO SEMPRE</strong>, ou terceiros indicados por este, a
              realizar o tratamento dos dados pessoais fornecidos durante o uso da{' '}
              <strong>Área do Cliente</strong>, por prazo indeterminado, para fins de implemento do
              clube de benefícios e dos serviços funerários contratados, bem como para fins de envio
              de avisos, materiais publicitários e newsletters, entre outros.
            </Text>
            <Text variant="body2">
              O <strong>ENCARREGADO</strong> indicado pelo <strong>PLANO SEMPRE</strong> para atuar
              como canal de comunicação em temas voltados para dados pessoais, poderá ser contatado
              da seguinte forma:
            </Text>
            <Text variant="body2">
              <strong>(DPO – DATA PROTECTION OFFICER)</strong>
              <br />
              <strong>
                [
                <Link href={'tel:0800 402 2002'} variant="body2" fontWeight="bold">
                  0800 402 2002
                </Link>
                ] - [
                <Link href={'mailto:dpo@empresavila.com.br'} variant="body2" fontWeight="bold">
                  dpo@empresavila.com.br
                </Link>
                ]
              </strong>
              <br />
              Para maiores informações, consulte nossa política de privacidade de dados disponível
              no site:{' '}
              <Link
                href={'https://planosempre.com.br/politica-de-privacidade/'}
                target={'_blank'}
                color={theme.palette.primary.main}
                variant="body2"
                fontStyle="italic"
              >
                https://planosempre.com.br/politica-de-privacidade/
              </Link>
            </Text>
          </ContentInfo>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
