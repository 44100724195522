import { Badge, Box, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IhandleNotificationClick, NotificationItem } from 'src/components/NotificationItem';
import { IconButtonAnimate } from 'src/components/animate';
import { useNotifications } from 'src/contexts/NotificationContext';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';

export default function NotificationPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { notifications, getNotifications, removeNotification, markAsRead, loadingNotifications } =
    useNotifications();
  const theme = useTheme();

  const providenciasCount = notifications.filter(
    (notification) => notification.type === 'providencia'
  ).length;
  const mensagensCount = notifications.filter(
    (notification) => notification.type === 'mensagem'
  ).length;

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);

  const handleViewAll = () => {
    navigate('/dashboard/user/notifications'); // Use PATH_DASHBOARD se necessário
    handleClose();
  };

  const handleItemClick = async (props: IhandleNotificationClick) => {
    const { batch, title, viewed } = props;

    if (title) {
      if (title.includes('dependente')) {
        navigate('/dashboard/user/dependents');
      } else if (title.includes('pagamento')) {
        navigate('/dashboard/historic');
      }
      handleClose();
    }
    if (!viewed) {
      await markAsRead(batch).then(() => {
        navigate('/dashboard/user/notifications');
        handleClose();
      });
    }
  };

  return (
    <>
      <Box display="flex" alignContent="center" justifyContent="center">
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            ml: 1,
            color: open ? 'common.white' : '#7DAFE8',
            backgroundColor: open ? 'primary.main' : 'transparent',
            '&:hover': {
              backgroundColor: open ? 'primary.dark' : theme.palette.action.hover,
            },
          }}
        >
          <Badge
            badgeContent={
              loadingNotifications
                ? 0
                : notifications.filter((notification) => !notification.opened).length
            }
            color="error"
          >
            <Iconify icon="ic:outline-notifications" />
          </Badge>
        </IconButtonAnimate>
        {loadingNotifications && (
          <CircularProgress
            size={45}
            sx={{
              color: '#7DAFE8',
              position: 'absolute',
              marginLeft: 1,
            }}
          />
        )}
      </Box>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 400, maxWidth: '100%', p: 0 }}
      >
        {/* Cabeçalho do popover */}
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Notificações</Typography>
        </Box>

        <Divider />

        {/* Lista de notificações ou mensagem de "Nenhuma notificação" */}
        <Box
          sx={{
            maxHeight: 300,
            overflowX: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f0f0f0',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '2px',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: '#555',
            },
          }}
        >
          {loadingNotifications ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              p={3}
            >
              <CircularProgress />

              <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 3 }}>
                Buscando notificações...
              </Typography>
            </Box>
          ) : (
            <>
              {/* Providências */}
              {notifications.some((notification) => notification.type === 'providencia') && (
                <>
                  <Typography
                    sx={{
                      mt: 2,
                      pl: 2,
                    }}
                    variant="subtitle2"
                    fontSize="10px"
                    fontWeight="bold"
                    color="#555"
                  >
                    PROVIDÊNCIAS ({providenciasCount})
                  </Typography>
                  {notifications
                    .filter((notification) => notification.type === 'providencia')
                    .slice(0, 10)
                    .map((notification) => (
                      <NotificationItem
                        noWrap={true}
                        key={notification.id}
                        batch={notification.batch}
                        title={notification.title}
                        description={notification.body}
                        timestamp={notification.createdAt}
                        viewed={notification.opened}
                        onRemove={removeNotification}
                        handleNotificationClick={handleItemClick}
                        type="providencia"
                      />
                    ))}
                </>
              )}
              {/* Mensagens */}
              {notifications.some((notification) => notification.type === 'mensagem') && (
                <>
                  <Typography
                    sx={{
                      mt: 2,
                      pl: 2,
                      mb: 0,
                      pb: 0,
                    }}
                    fontSize="10px"
                    variant="subtitle2"
                    fontWeight="bold"
                    color="#555"
                  >
                    MENSAGENS ({mensagensCount})
                  </Typography>
                  {notifications
                    .filter((notification) => notification.type === 'mensagem')
                    .slice(0, 10)
                    .map((notification) => (
                      <NotificationItem
                        noWrap={true}
                        key={notification.id}
                        batch={notification.batch}
                        title={notification.title}
                        description={notification.body}
                        timestamp={notification.createdAt}
                        viewed={notification.opened}
                        onRemove={removeNotification}
                        handleNotificationClick={handleItemClick}
                        type="mensagem"
                      />
                    ))}
                </>
              )}
              {providenciasCount === 0 && mensagensCount === 0 && (
                <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Iconify icon="ic:outline-notifications-off" width={24} height={24} mb={1} />
                  <Typography variant="h6" sx={{ textAlign: 'center' }}>
                    Não há novas notificações
                  </Typography>
                  <Typography variant="caption" sx={{ textAlign: 'center' }}>
                    Fique tranquilo, qualquer novidade importante nós te informaremos.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>

        <Divider />

        {/* Rodapé do popover */}
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography
            variant="body2"
            sx={{ cursor: 'pointer', color: 'primary.main' }}
            onClick={handleViewAll}
          >
            Ver todas
          </Typography>
        </Box>
      </MenuPopover>
    </>
  );
}

