import { Box, Button, Card, Container, Divider, Switch, Typography, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import moment from 'moment';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import { varFade } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { cardDependPDF } from 'src/sections/@dashboard/DigitalCard/DigitalCardDependentPDF';
import { cardTitularPDF } from 'src/sections/@dashboard/DigitalCard/DigitalCardTitularPDF';
import { FaqsPreview } from 'src/sections/@dashboard/general/app/FaqsPreview';
import axiosInstance from 'src/utils/axios';
import IPlan, { IDependent } from './@types';

const DependentsArea = styled.div`
  max-height: 85px;
  width: 250px;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export default DependentsArea;

export function DigitalCard() {
  const { themeStretch } = useSettings();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const [listPlans, setListPlans] = useState<IPlan[]>([]);
  const [listDependents, setListDependents] = useState<IDependent[]>([]);
  const [removeLoading, setRemoveLoading] = useState(false);
  let isCardDependents = false;
  const [token, setToken] = useState('');
  const [dateToken, setDateToken] = useState('');
  const [isToken, setIsToken] = useState(false);
  const [titular, setTitular] = useState('');

  const [backCard, setBackCard] = useState(false);

  const handleChange = () => {
    setBackCard(!backCard);
  };

  const handleClickOpen = async () => {
    var { data } = await axiosInstance.post(`/users/card/generate`, {
      user_id: user.id,
      contract_id: listPlans[0].id,
    });
    setToken(data.token);
    setDateToken(data.updated_at);
    setIsToken(true);
  };

  const getInfoCard = async () => {
    try {
      const { data: contractsData } = await axiosInstance.get(`/users/contract/${user.id}`);

      // Filtra apenas contratos ativos e exclui "Jazigo", utilizando a interface IPlan para tipagem
      const activeContracts: IPlan[] = contractsData.filter(
        (contract: IPlan) => contract.active && !contract.name.toLowerCase().includes('jazigo')
      );
      setListPlans(activeContracts);

      const { data: dataToken } = await axiosInstance.get(`/users/card/list/${user.id}`);
      setToken(dataToken.token);
      setDateToken(dataToken.created_at);

      if (user.role === 'USER') {
        const { data: dependentsData } = await axiosInstance.get(`/users/dependents/${user.id}`);

        // Utiliza a interface IDependent para tipagem dos dependentes
        const activeContractIds: string[] = activeContracts.map((contract) => contract.id);

        const activeDependents: IDependent[] = dependentsData.filter(
          (dependent: IDependent) =>
            dependent.active && activeContractIds.includes(dependent.belongs_contract)
        );

        // Filtra os dependentes para excluir registros com 'recno' nulo ou vazio
        const filteredDependents = activeDependents.filter((dep) => dep.recno);

        const uniqueDependents: IDependent[] = filteredDependents
          .filter(
            (dep: IDependent, index: number, self: IDependent[]) =>
              index ===
              self.findIndex(
                (t: IDependent) =>
                  t.name === dep.name && t.birthdate === dep.birthdate && t.recno === dep.recno
              )
          )
          .sort((a: IDependent, b: IDependent) => a.name.localeCompare(b.name));

        setListDependents(uniqueDependents);
      } else {
        const { data: userTitularData } = await axiosInstance.get(
          `/users/getUserById/${user.user_id}`
        );
        setTitular(userTitularData.name);
      }
      setRemoveLoading(true);
    } catch (error) {
      console.error('Erro ao carregar Carteira Virtual:', error);
    }
  };

  useEffect(() => {
    getInfoCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Carteirinha digital">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
            heading={`Carteirinha digital`}
            links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Carteirinha digital' }]}
          />
        </div>
        <Divider sx={{ marginBottom: '20px' }} />
        <Card sx={{ padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '290px',
                margin: 'o auto',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant={!isToken ? 'contained' : 'outlined'}
                onClick={() => setIsToken(false)}
              >
                Carteirinha
              </Button>
              <Button
                onClick={() => handleClickOpen()}
                variant={isToken ? 'contained' : 'outlined'}
                style={{ marginLeft: '20px' }}
              >
                Gerar token
              </Button>
            </div>
          </div>
          {!isToken && (
            <div>
              {!backCard && (
                <Box component={m.div} variants={varFade().in} sx={{ px: 0.5, py: 0 }}>
                  <Card
                    style={{
                      width: '290px',
                      height: '198px',
                      margin: '5px auto',
                      backgroundImage: `url("/assets/illustrations/NovoCartão_PlanoSempre.svg")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      boxShadow: ' 0px 0px 5px 1px rgba(0,0,0,0.63)',
                    }}
                  />
                </Box>
              )}
              {backCard && (
                <Box component={m.div} variants={varFade().in} sx={{ px: 0.5, py: 0 }}>
                  <Card
                    style={{
                      width: '290px',
                      height: '198px',
                      padding: '20px',
                      margin: '5px auto',
                      boxShadow: ' 0px 0px 5px 1px rgba(0,0,0,0.63)',
                      backgroundColor: 'white',
                      position: 'relative',
                    }}
                  >
                    {!isCardDependents ? (
                      <div style={{ marginBottom: '5px' }}>
                        <Typography
                          textTransform="uppercase"
                          fontWeight={700}
                          fontSize="9px"
                          color={theme.palette.grey[700]}
                        >
                          {user.name}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box mt="5px">
                            <Typography
                              fontWeight={700}
                              fontSize="8px"
                              color={theme.palette.grey[700]}
                            >
                              Nº DO CONTRATO:
                            </Typography>
                            {listPlans.map((i: any) => (
                              <Typography key={i.id} fontSize="8px" color={theme.palette.grey[700]}>
                                {i.contract_number}
                              </Typography>
                            ))}
                          </Box>
                          <Box mt="5px">
                            <Typography
                              fontWeight={700}
                              fontSize="8px"
                              color={theme.palette.grey[700]}
                            >
                              DATA DE ADESÃO
                            </Typography>
                            {listPlans.map((i: any) => (
                              <Typography key={i.id} fontSize="8px" color={theme.palette.grey[700]}>
                                {moment(i.date_issued).format('DD/MM/YYYY')}
                              </Typography>
                            ))}
                          </Box>
                          <Box mt="5px">
                            <Typography
                              fontWeight={700}
                              fontSize="8px"
                              color={theme.palette.grey[700]}
                            >
                              PLANO
                            </Typography>

                            {listPlans.map((i: any) => (
                              <Typography
                                key={i.id}
                                textTransform="uppercase"
                                fontSize="8px"
                                color={theme.palette.grey[700]}
                              >
                                {i.name}
                              </Typography>
                            ))}
                          </Box>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!isCardDependents ? (
                      <div style={{ display: 'flex' }}>
                        {
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                              border: 'none',
                            }}
                          >
                            {user.role !== 'USER' ? (
                              <>
                                <Typography
                                  fontWeight={600}
                                  marginTop="10px"
                                  fontSize="9px"
                                  color={theme.palette.primary.main}
                                >
                                  TITULAR
                                </Typography>
                                <Typography
                                  fontWeight={600}
                                  fontSize="8px"
                                  color={theme.palette.grey[700]}
                                >
                                  {titular}
                                </Typography>
                              </>
                            ) : (
                              <Typography
                                fontWeight={700}
                                fontSize="8px"
                                color={theme.palette.grey[700]}
                              >
                                DEPENDENTES:
                              </Typography>
                            )}
                            <DependentsArea>
                              {removeLoading &&
                                user.role === 'USER' &&
                                listDependents.map((item: any) => (
                                  <Typography
                                    key={item.id}
                                    textTransform="uppercase"
                                    fontSize="8px"
                                    color={theme.palette.grey[700]}
                                  >
                                    {item.name} - {item.contract_number}
                                  </Typography>
                                ))}
                            </DependentsArea>
                          </div>
                        }
                      </div>
                    ) : (
                      ''
                    )}
                  </Card>
                </Box>
              )}
            </div>
          )}
          {!isToken && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color={!backCard ? theme.palette.primary.main : theme.palette.grey[700]}>
                Frente
              </Typography>
              <Switch
                checked={backCard}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography color={backCard ? theme.palette.primary.main : theme.palette.grey[700]}>
                Verso
              </Typography>
            </div>
          )}
          {isToken && (
            <Box component={m.div} variants={varFade().in} sx={{ px: 0.5, py: 0 }}>
              <Card
                style={{
                  width: '290px',
                  height: '198px',
                  margin: '5px auto',
                  padding: '10px',
                  boxShadow: ' 0px 0px 5px 1px rgba(0,0,0,0.63)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body1" color={theme.palette.grey[600]}>
                  Token gerado com sucesso!
                </Typography>

                <Typography variant="h3" color={theme.palette.primary.main} fontWeight={700}>
                  {token}
                </Typography>

                <Typography variant="body2" color={theme.palette.grey[600]}>
                  {moment(dateToken).format('lll')}
                </Typography>
              </Card>
            </Box>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              fullWidth
              size="large"
              variant="outlined"
              style={{
                maxWidth: '400px',
                margin: '30px 0 40px',
                paddingRight: 0,
                color: `black`,
                borderColor: 'grey',
              }}
              onClick={() => {
                if (user.role === 'USER') {
                  // Cria listas com as informações de todos os planos
                  const contractNumbers = listPlans.map((plan: IPlan) => plan.contract_number);
                  const issueDates = listPlans.map((plan: IPlan) => plan.date_issued);
                  const planNames = listPlans.map((plan: IPlan) => plan.name);

                  // Chama a função cardTitularPDF com as listas completas
                  cardTitularPDF(
                    user.name,
                    user.birthdate,
                    contractNumbers,
                    issueDates,
                    planNames,
                    listDependents,
                    token,
                    dateToken,
                    theme
                  );
                } else {
                  cardDependPDF(
                    user.name,
                    titular,
                    user.birthdate,
                    listPlans[0].contract_number,
                    listPlans[0].date_issued,
                    listPlans[0].name,
                    token,
                    dateToken,
                    theme
                  );
                }
              }}
            >
              Gerar PDF
              <Iconify icon="vscode-icons:file-type-pdf2" margin="15px" fontSize="25px" />
            </Button>
            {user.role === 'USER' && (
              <Button
                fullWidth
                size="large"
                variant="outlined"
                style={{ paddingRight: 0, maxWidth: '400px' }}
                onClick={() => navigate(PATH_DASHBOARD.general.contract)}
              >
                Acessar meu plano sempre
                <Iconify icon="ic:sharp-launch" margin="15px" />
              </Button>
            )}
          </div>
        </Card>
        <FaqsPreview />
      </Container>
    </Page>
  );
}

