import * as Yup from 'yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  Box,
  useTheme,
  TextField,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import axiosInstance from 'src/utils/axios';
import InputMask from 'react-input-mask';
import { regexCamp } from '../updateAccount/UpdateInitialForm';
import { UpdateFormProps } from '../updateAccount/UpdateForm';
import { PasswordRequirement } from '../reset-password/PasswordRequirement';
// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  contact: string;
  cpf: string;
  birthdate: string;
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function RegisterLeadForm({
  handleOpenPrivateModal,
  handleOpenPrivacyModal,
}: UpdateFormProps) {
  const { login, method } = useAuth();

  const theme = useTheme();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const regexPassword =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@#$%^&+!=?*-/]).{8,20}$/gm;

  const RegisterLeadSchema = Yup.object().shape({
    name: Yup.string().required('Digite o nome completo').nullable(),
    contact: Yup.string()
      .required('Digite o número de contato')
      .test(
        'Digite o número de celular corretamente!',
        'Digite o número de celular corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    cpf: Yup.string()
      .required('Digite um CPF')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    birthdate: Yup.date().required('Digite a data de nascimento'),
    email: Yup.string()
      .email('O e-mail deve ser um endereço de e-mail válido')
      .required('Email é Obrigatório')
      .nullable()
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
    password: Yup.string()
      .required('Senha é obrigatório')
      .matches(regexPassword, 'A senha não atende os requisitos de segurança!'),
    confirmPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
    agreeTerms: Yup.boolean()
      .oneOf([true], 'Você deve aceitar os Termos e Condições.')
      .required('Você deve aceitar os Termos e Condições.'),
    newsletter: Yup.boolean(),
  });

  const maxDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterLeadSchema),
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  function formatDate(date: string) {
    const newDate = new Date(date);
    const dateFormatted = newDate.toISOString();
    const datePart = dateFormatted.split('T');
    return `${datePart[0]}`;
  }

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post('/users/lead/create', {
        birthdate: formatDate(data.birthdate),
        name: data.name,
        cpf: data.cpf.replace(/[^0-9]/g, ''),
        phone_number: data.contact.replace(/[^0-9]/g, ''),
        email: data.email,
        password: data.password,
      });
      try {
        await login(data.cpf.replace(/[^0-9]/g, ''), data.password);
      } catch (error) {
        console.log('Error no login!');
      }

      window.location.href = PATH_DASHBOARD.general.app;
    } catch (error) {
      console.error(error);

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Nome Completo
          </Typography>
          <RHFTextField name="name" />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            CPF
          </Typography>

          <Controller
            name="cpf"
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask mask="999.999.999-99" value={value} onChange={onChange}>
                <TextField
                  name="cpf"
                  type="text"
                  fullWidth
                  error={Boolean(errors.cpf)}
                  helperText={error?.message}
                />
              </InputMask>
            )}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Data de Nascimento
          </Typography>
          <RHFTextField name="birthdate" type="date" inputProps={{ max: maxDate }} />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Contato
          </Typography>
          <Controller
            name="contact"
            control={methods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask mask="(99)9 9999-9999" value={value} onChange={onChange}>
                <TextField
                  name="contact"
                  type="text"
                  fullWidth
                  error={Boolean(errors.contact)}
                  helperText={error?.message}
                />
              </InputMask>
            )}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            E-mail
          </Typography>
          <RHFTextField name="email" />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Senha
          </Typography>
          <RHFTextField
            name="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Confirmar Senha
          </Typography>
          <RHFTextField
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <PasswordRequirement />
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Button onClick={handleOpenPrivateModal}>Termos de Uso</Button> e{' '}
          <Button onClick={handleOpenPrivacyModal}>Políticas de Privacidade.</Button>
        </Box>

        <RHFCheckbox
          name="agreeTerms"
          label={`Confirmo que li e estou de acordo com os Termos de Uso e Políticas de Privacidade.`}
        />
        <RHFCheckbox
          name="newsletter"
          label="Desejo assinar a Newsletter e receber notícias e ofertas através de e-mail, SMS e Whastapp."
        />

        <Tooltip title={method} placement="right">
          <></>
        </Tooltip>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ my: 2 }}
      >
        Cadastrar
      </LoadingButton>
    </FormProvider>
  );
}
