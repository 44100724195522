import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import useAuth from '../hooks/useAuth';
import Avatar, { Props as AvatarProps } from './Avatar';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  const [imageUrl, setImageUrl] = useState('');
  const getPresignedURL = async () => {
    const responseURL = await axiosInstance.get(`/users/presigned-photos/${user.id}`);
    setImageUrl(responseURL.data);
  };
  useEffect(() => {
    getPresignedURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Avatar
      src={imageUrl}
      alt={user?.displayName}
      color={imageUrl ? 'default' : 'primary'}
      {...other}
    >
      <Iconify icon={'fa-regular:user'} />
    </Avatar>
  );
}

