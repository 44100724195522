import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import MyAvatar from '../../../components/MyAvatar';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};
function formatDate(date: Date) {
  return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
}
export default function NavbarAccount({ isCollapse }: Props) {
  const { user } = useAuth();

  const nameSplit = user?.name.split(' ');
  const firstLastName = nameSplit[0] + ' ' + nameSplit[nameSplit.length - 1];
  const firstMidlleName = nameSplit[0] + ' ' + nameSplit[1];

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.settings}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" width={isCollapse ? '' : '150px'} noWrap>
            {user.name.lenght < 18
              ? user.name
              : firstLastName.length < 18
              ? firstLastName
              : firstMidlleName.length < 18
              ? firstMidlleName
              : nameSplit[0]}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.role === 'USER' || user?.role === 'PROPOSAL_USER'
              ? 'Titular'
              : user?.role === 'LEAD'
              ? `Expira em: ${formatDate(user.access_expire_in)}`
              : 'Dependente'}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
