import React, { ReactNode } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

interface Props extends React.HTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

export default function FormProvider({ children, onSubmit, methods, ...props }: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} {...props}>{children}</form>
    </Form >
  );
}
