import { useState } from 'react';

import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from 'src/components/Page';
import SimpleHeader from 'src/components/Header/SimpleHeader';
import { LoginLeadForm } from 'src/sections/auth/loginLead/LoginLeadForm';
import { BenefitsModal } from 'src/sections/@dashboard/user/modals/BenefitsModal';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export function LoginLead() {
  // const { method } = useAuth();

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  const theme = useTheme();

  return (
    <Page title="Login">
      <RootStyle>
        <SimpleHeader />
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  color="primary"
                  fontSize={'20px'}
                  lineHeight={'30px'}
                  fontWeight={'600'}
                  marginBottom={'10px'}
                >
                  Seja bem-vindo(a) a área do cliente!
                </Typography>
                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'10x'}
                >
                  Experimente a área do cliente durante 07 dias, de forma gratuita.
                </Typography>

                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'1rem'}
                >
                  Aproveite nosso clube de benefícios e confira agora mesmo os parceiros da sua
                  região!
                </Typography>

                <Typography
                  sx={{ color: 'text.secondary' }}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  fontWeight={'400'}
                  marginTop={'1rem'}
                >
                  Entre com o seu e-mail e senha.
                </Typography>
              </Box>

              {/* <Tooltip title={method} placement="right">
                <></>
              </Tooltip> */}
            </Stack>

            <LoginLeadForm handleOpenModal={handleOpen} />

            <Box>
              <Typography
                variant="body1"
                align="left"
                sx={{ mt: 3 }}
                fontWeight="bold"
                marginBottom="20px"
              >
                Não possui cadastro e quer aproveitar{' '}
                <span
                  style={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  07 dias gratuitos{' '}
                </span>
                no portal?
              </Typography>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                type="submit"
                href="/auth/register-lead"
              >
                Cadastrar
              </LoadingButton>
            </Box>

            <Box>
              <Typography
                variant="body1"
                align="left"
                sx={{ mt: 3 }}
                fontWeight="bold"
                marginBottom="20px"
              >
                Seu acesso expirou?
              </Typography>
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => window.location.assign('https://www.planosempre.com.br')}
              >
                Torne-se um cliente
              </LoadingButton>
            </Box>
          </ContentStyle>
        </Container>
        <BenefitsModal onClose={handleClose} open={open} />
      </RootStyle>
    </Page>
  );
}
