import { Box, BoxProps, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  src: string;
}

export default function SvgIconStyle({ src, sx }: Props) {
  const theme = useTheme();

  return (
    <Box
      component="span"
      sx={{
        width: 22,
        height: 22,
        display: 'inline-block',
        bgcolor: theme.palette.primary.light,
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
    />
  );
}
