import { Typography, Dialog, DialogContent, Button } from '@mui/material';
import axiosInstance from 'src/utils/axios';

interface CrcModalProps {
  open: boolean;
  handleClose: () => void;
  dataForm: any;
  text: string;
}

export function CrcModal({ open, handleClose, dataForm, text }: CrcModalProps) {
  async function callCrc() {
    try {
      await axiosInstance.post('/users/call-crc-log', {
        document: dataForm.document,
        birthdate: dataForm.birthdate,
      });

      const a = document.createElement('a');
      a.href = 'tel:8440002002';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  async function callCrcWhatsapp() {
    try {
      await axiosInstance.post('/users/call-crc-log', {
        document: dataForm.document,
        birthdate: dataForm.birthdate,
      });

      const a = document.createElement('a');
      a.href = 'https://wa.me/558440002002';
      a.target = '_blank';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent
        sx={{ maxWidth: '320px', padding: '35px 48px', display: 'flex', flexDirection: 'column' }}
      >
        <img src="/assets/illustrations/call_crc.svg" alt="call crc" />

        <Typography variant="subtitle2" sx={{ textAlign: 'center', marginTop: '35px' }}>
          {text}
        </Typography>

        <Button variant="contained" sx={{ marginTop: '35px' }} onClick={callCrc}>
          Fale Conosco Por Telefone
        </Button>

        <Button variant="contained" sx={{ marginTop: '35px' }} onClick={callCrcWhatsapp}>
          Fale Conosco Por Whatsapp
        </Button>

        <Button sx={{ marginTop: '35px' }} onClick={handleClose}>
          Tentar novamente
        </Button>
      </DialogContent>
    </Dialog>
  );
}
