import { Box, ListItemIcon, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import Linkify from 'react-linkify';
import Iconify from './Iconify';

export interface IhandleNotificationClick {
  batch: string;
  viewed?: boolean;
  title?: string;
}

export interface NotificationItemProps {
  noWrap: boolean;
  batch: string;
  title: string;
  description: string;
  timestamp: string;
  viewed: boolean;
  type: string;
  onRemove: (id: number) => void;
  handleNotificationClick?: (props: IhandleNotificationClick) => void;
}

export const NotificationItem = ({
  batch,
  title,
  description,
  timestamp,
  viewed,
  type,
  handleNotificationClick,
  noWrap,
}: NotificationItemProps) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(false);

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '6px 16px 6px 16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: viewed ? 'rgba(145, 158, 171, 0.05)' : 'rgba(145, 158, 171, 0.05)',
        m: 1,
        borderRadius: '12px',
        cursor: handleNotificationClick ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: viewed ? 'rgba(145, 158, 171, 0.08)' : 'rgba(145, 158, 171, 0.08)',
        },
      }}
      onMouseEnter={() => setShowRemoveIcon(true)}
      onMouseLeave={() => setShowRemoveIcon(false)}
      onClick={() =>
        handleNotificationClick &&
        handleNotificationClick({
          batch: batch,
          title: title,
          viewed: viewed,
        })
      }
    >
      <Box sx={{ flexGrow: 1, overflow: 'hidden', m: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
          noWrap={noWrap}
          align="justify"
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                href={decoratedHref}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                {decoratedText}
              </a>
            )}
          >
            {description}
          </Linkify>
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {moment(timestamp).fromNow()}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {type === 'mensagem' && showRemoveIcon && (
          <></>
          // <IconButton sx={{ mr: 1, }} size="medium" onClick={(event) => { event.stopPropagation(); onRemove(id); }}>
          //   <Iconify icon="eva:trash-2-outline" />
          // </IconButton>
        )}
        {type === 'providencia' && (
          <>
            {title.includes('pagamento') && (
              <Iconify
                icon="tabler:coin"
                width={20}
                height={20}
                mr={1}
                color={theme.palette.info.dark}
              />
            )}
            {title.includes('dependente') && (
              <Iconify
                icon="ic:baseline-warning"
                width={20}
                height={20}
                mr={1}
                color={theme.palette.error.main}
              />
            )}
          </>
        )}
        {!viewed && type !== 'providencia' && (
          <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
            <span
              style={{
                height: '12px',
                width: '12px',
                backgroundColor: '#184981',
                borderRadius: '50%',
                display: 'inline-block',
              }}
            />
          </ListItemIcon>
        )}
      </Box>
    </Box>
  );
};

