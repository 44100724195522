import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { maritalStatusList } from 'src/@types/maritalStatus';
import { statesBR } from 'src/@types/statesBR';
import Iconify from 'src/components/Iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getCityProps } from 'src/sections/auth/updateAccount/UpdateForm';
import { regexCamp } from 'src/sections/auth/updateAccount/UpdateInitialForm';
import axiosInstance from 'src/utils/axios';
import * as Yup from 'yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import { CustomFile } from '../../../../components/upload';
import useAuth from '../../../../hooks/useAuth';
import { ProfileImageCrop } from './ProfileImageCrop';

// ----------------------------------------------------------------------

type FormValuesProps = {
  cpf: string;
  birthDate: Date;
  name: string;
  email: string;
  image: CustomFile | null | string;
  phoneNumber: string;
  homePhone: string;
  otherPhone: string;
  address: string;
  house_number: string;
  neighborhood: string;
  complement: string;
  reference_point: string;
  state: string;
  city: string;
  zipCode: string;
  maritalStatus: string;
  newsletter: boolean;
};

let listCity: getCityProps[] = [];

export default function ProfileGeneralSettings() {
  const { user } = useAuth();
  const theme = useTheme();

  const [newsletterCheckBox, setNewsletterCheckBox] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageSelected, setImageSelected] = useState<CustomFile | undefined>(undefined);
  const getPresignedURL = async () => {
    const responseURL = await axiosInstance.get(`users/presigned-photos/${user.id}`);
    setImageUrl(responseURL.data);
  };
  const [open, setOpen] = useState(false);

  function handleClose() {
    setValue('image', undefined);
    setImageSelected(undefined);
    setOpen(false);
  }

  const [listCityByState, setListCityByState] = useState<getCityProps[]>([]);

  const UpdateUserSchema = Yup.object().shape({
    image: Yup.mixed()
      .nullable()
      .test(
        'fileType',
        'Tipo de arquivo inválido. Permitido apenas JPEG e PNG',
        (value) =>
          value === null || (value && (value.type === 'image/jpeg' || value.type === 'image/png'))
      )
      .test('fileSize', 'Tamanho permitido até 2MB', (value) => {
        if (!value) return true;
        return value && value.size <= 2 * 1024 * 1024;
      }),
    name: Yup.string().required('O nome completo').nullable(),
    cpf: Yup.string().required('O CPF é obrigatório').nullable(),
    email: Yup.string()
      .email('Digite um E-mail valido')
      .required('Digite o e-mail')
      .nullable()
      .max(50, 'O e-mail deve ter no máximo 50 caracteres'),
    phoneNumber: Yup.string()
      .required('Digite o número do celular')
      .test(
        'Digite o número do celular corretamente!',
        'Digite o número do celular corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    homePhone: Yup.string()
      .test(
        'Digite o número do telefone corretamente!',
        'Digite o número do telefone corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    otherPhone: Yup.string()
      .test(
        'Digite o número do telefone corretamente!',
        'Digite o número do telefone corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    zipCode: Yup.string()
      .required('Digite o CEP')
      .test(
        'Digite o CEP corretamente!',
        'Digite o CEP corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    address: Yup.string()
      .required('Digite o Logradouro')
      .nullable()
      .max(40, 'O Logradouro deve ter no máximo 40 caracteres'),
    house_number: Yup.string()
      .required('Digite o Número')
      .nullable()
      .max(15, 'O Número da residência deve ter no máximo 15 caracteres'),
    neighborhood: Yup.string()
      .required('Digite o Bairro')
      .nullable()
      .max(30, 'O bairro deve ter no máximo 30 caracteres'),
    complement: Yup.string().nullable().max(50, 'O complemento deve ter no máximo 50 caracteres'),
    reference_point: Yup.string()
      .required('Digite o Ponto de Referência')
      .nullable()
      .max(70, 'O Ponto de referência deve ter no máximo 70 caracteres'),
    city: Yup.string().required('Digite a Cidade').nullable(),
    state: Yup.string().required('O Estado é obrigatório').nullable(),
    maritalStatus: Yup.string().required('O Estado Civil é Obrigatório').nullable(),
  });

  const defaultValues = {
    cpf: user?.cpf || '',
    birthDate: user?.birthdate || '',
    name: user?.name || '',
    email: user?.email || '',
    image: imageUrl || null,
    phoneNumber: user?.phone_number || '',
    homePhone: user?.home_phone || '',
    otherPhone: user?.other_phone || '',
    address: user?.adress || '',
    house_number: user?.house_number || null,
    neighborhood: user?.neighborhood || null,
    complement: user?.complement || null,
    reference_point: user?.reference_point || null,
    state: user?.state || '',
    city: user?.city || '',
    zipCode: user?.zip_code || '',
    maritalStatus: user?.marital_status || null,
    newsletter: user?.newsletter || null,
  };

  const methods: any = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  async function getCity(value: string) {
    setListCityByState([]);
    listCity = [];
    setValue('state', value);
    let { data } = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
    );

    // eslint-disable-next-line array-callback-return
    await data.map((i: any) => {
      let resCity = {
        id: i.id,
        city: i.nome,
      };

      listCity.push(resCity);
    });

    setListCityByState(listCity);
  }

  async function getLocationForCep() {
    const cep = getValues('zipCode').replace(/[^\d]+/g, '');

    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      if (data.erro === true) {
        toast.error('Erro ao buscar o CEP, preencha as informações manualmente!', {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        });
      }

      const { localidade, logradouro, uf, bairro } = data;

      setValue('address', logradouro);
      setValue('neighborhood', bairro);
      setValue('city', localidade);
      setValue('state', uf);

      getCity(getValues('state'));
    } catch (err) {
      toast.error('Não foi possivel pegar os dados pelo cep!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });

      setValue('address', '');
      setValue('neighborhood', ' ');
      setValue('city', ' ');
      setValue('state', ' ');
    }
  }

  async function getLocationForCepInitial() {
    const cep = getValues('zipCode').replace(/[^\d]+/g, '');

    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const { localidade, uf } = data;
      setValue('city', localidade);
      setValue('state', uf);
      getCity(getValues('state'));
    } catch (err) {
      setValue('state', user.state);
      setValue('city', user.city);
    }
  }

  async function renderCity() {
    getCity(getValues('state'));
  }

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const cityUser = await listCityByState.find((i: any) => i.city === data.city);

      if ((user.image && user.image === data.image) || !data.image) {
        await axiosInstance.patch(`/users/${user.id}`, {
          name: data.name,
          phone_number: data.phoneNumber.replace(/[^0-9]/g, ''),
          home_phone: data.homePhone.replace(/[^0-9]/g, ''),
          other_phone: data.otherPhone.replace(/[^0-9]/g, ''),
          email: data.email,
          zip_code: data.zipCode.replace(/[^0-9]/g, ''),
          adress: data.address,
          house_number: data.house_number,
          neighborhood: data.neighborhood,
          complement: data.complement || null,
          reference_point: data.reference_point || null,
          cod_city: cityUser?.id.toString().substr(2),
          city: data.city,
          state: data.state,
          marital_status: data.maritalStatus,
          newsletter: newsletterCheckBox ? !defaultValues.newsletter : defaultValues.newsletter,
          updated_at: new Date(),
        });
      } else {
        const formData = new FormData();
        formData.append('file', data.image);

        const responseImage = await axiosInstance.post(`/users/uploadFile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await axiosInstance.patch(`/users/${user.id}`, {
          name: data.name,
          phone_number: data.phoneNumber.replace(/[^0-9]/g, ''),
          home_phone: data.homePhone.replace(/[^0-9]/g, ''),
          other_phone: data.otherPhone.replace(/[^0-9]/g, ''),
          email: data.email,
          zip_code: data.zipCode.replace(/[^0-9]/g, ''),
          adress: data.address,
          house_number: data.house_number,
          neighborhood: data.neighborhood,
          complement: data.complement || null,
          reference_point: data.reference_point || null,
          cod_city: cityUser?.id.toString().substr(2),
          city: data.city,
          state: data.state,
          marital_status: data.maritalStatus,
          image: responseImage.data,
          newsletter: newsletterCheckBox ? !defaultValues.newsletter : defaultValues.newsletter,
          updated_at: new Date(),
        });
      }

      toast.success('Informações alteradas com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      setTimeout(() => {
        window.location.href = PATH_DASHBOARD.general.app;
      }, 3000);
    } catch (error) {
      console.error(error);
      toast.error(`${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      setImageSelected(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setOpen(true);
    }
  }, []);

  const handleSaveCroppedImage = (file: any) => {
    if (file) {
      setValue(
        'image',
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setImageSelected(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setOpen(false);
    }
  };

  useEffect(() => {
    getLocationForCepInitial();
    getPresignedURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <ProfileImageCrop
        onClose={handleClose}
        open={open}
        imageURL={imageSelected}
        handleSaveCroppedImage={handleSaveCroppedImage}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 3, px: 3, textAlign: 'center' }}>
            <Typography
              variant="body1"
              textTransform={'uppercase'}
              margin="10px"
              fontSize="12px"
              sx={{
                mt: 0,
                mx: 'auto',
                display: 'block',
                textAlign: 'left',
                color: 'text.secondary',
              }}
            >
              Imagem de perfil
            </Typography>
            <Divider sx={{ marginBottom: '15px' }} />
            <RHFUploadAvatar
              name="image"
              maxSize={2097152}
              onDrop={handleDrop}
              fileURL={imageUrl}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Permitido *.jpeg, *.png. Tamanho Máximo: 2 MB
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Typography
              variant="body1"
              textTransform={'uppercase'}
              margin="10px"
              fontSize="12px"
              sx={{
                mt: 0,
                mx: 'auto',
                display: 'block',
                textAlign: 'left',
                color: 'text.secondary',
              }}
            >
              dados pessoais
            </Typography>
            <Divider sx={{ marginBottom: '20px' }} />
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <Controller
                name="cpf"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="999.999.999-99" value={value} disabled onChange={onChange}>
                    <TextField
                      name="cpf"
                      type="text"
                      label="CPF *"
                      fullWidth
                      disabled
                      error={Boolean(errors.cpf)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <RHFTextField
                name="birthDate"
                label="Data de Nascimento *"
                type="date"
                variant="outlined"
                focused={true}
                disabled
              />
              <RHFTextField name="name" label="Nome Completo *" disabled />
              <RHFTextField name="email" type="email" label="Email *" />
              <Controller
                name="phoneNumber"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="phoneNumber"
                      label="Celular *"
                      type="text"
                      fullWidth
                      error={Boolean(errors.phoneNumber)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <Controller
                name="homePhone"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="homePhone"
                      label="Telefone Residencial"
                      type="text"
                      fullWidth
                      error={Boolean(errors.homePhone)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <Controller
                name="otherPhone"
                control={methods.control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask mask="(99) 9 9999-9999" value={value} onChange={onChange}>
                    <TextField
                      name="otherPhone"
                      label="Outro Telefone"
                      type="text"
                      fullWidth
                      error={Boolean(errors.otherPhone)}
                      helperText={error?.message}
                    />
                  </InputMask>
                )}
              />
              <RHFSelect name="maritalStatus" label="Estado Civil *">
                <option value="0" disabled selected>
                  Selecione
                </option>
                {maritalStatusList.map((i) =>
                  user?.martinal_status === i ? (
                    <option key={i} value={i} selected>
                      {i}
                    </option>
                  ) : (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  )
                )}
              </RHFSelect>
            </Box>
            <Box>
              <Typography
                variant="body1"
                textTransform={'uppercase'}
                margin="10px"
                fontSize="12px"
                sx={{
                  mt: '1rem',
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'left',
                  color: 'text.secondary',
                }}
              >
                endereço
              </Typography>
              <Divider sx={{ marginBottom: '20px' }} />
              <Grid container spacing={2}>
                {/* CEP */}
                <Grid item xs={12} sm={6} md={4}>
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <Controller
                      name="zipCode"
                      control={methods.control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputMask mask="99999-999" value={value} onChange={onChange}>
                          <TextField
                            sx={{ flex: 1 }}
                            name="zipCode"
                            type="text"
                            fullWidth
                            label="CEP *"
                            error={Boolean(errors.zipCode)}
                            helperText={error?.message}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                getLocationForCep();
                              }
                            }}
                          />
                        </InputMask>
                      )}
                    />
                    <Button
                      style={{ position: 'absolute', right: '0', height: '100%' }}
                      onClick={() => getLocationForCep()}
                    >
                      <Iconify icon="ic:baseline-search" fontSize={'24px'} />
                    </Button>
                  </div>
                </Grid>
                {/* Logradouro */}
                <Grid item xs={12} sm={6} md={8}>
                  <RHFTextField name="address" label="Logradouro *" fullWidth />
                </Grid>
                {/* Outros campos do endereço (Bairro, Número, Estado, Cidade, Complemento, Ponto de Referência) */}
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    {/* Bairro */}
                    <RHFTextField name="neighborhood" label="Bairro *" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {/* Número */}
                    <RHFTextField name="house_number" type="text" label="Número *" fullWidth />
                  </Grid>
                </Grid>
                {/* Estado e Cidade */}
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    {/* Estado */}
                    <RHFSelect
                      defaultValue="0"
                      name="state"
                      label="Estado *"
                      onChange={(e) => getCity(e.target.value)}
                      onClick={() => renderCity()}
                      fullWidth
                    >
                      <option value="0" selected>
                        Selecione
                      </option>
                      {statesBR.map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {/* Cidade */}
                    <RHFSelect
                      defaultValue="0"
                      name="city"
                      label="Cidade *"
                      disabled={listCityByState.length === 0 && true}
                      fullWidth
                    >
                      <option value="0" selected hidden>
                        Selecione
                      </option>
                      {listCityByState.map((i) => (
                        <option key={i.id} value={i.city}>
                          {i.city}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
                {/* Complemento */}
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="complement" label="Complemento" fullWidth />
                </Grid>
                {/* Ponto de Referência */}
                <Grid item xs={12} sm={6}>
                  <RHFTextField name="reference_point" label="Ponto de Referência *" fullWidth />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
            <div
              style={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <FormControlLabel
                onClick={() => {
                  setNewsletterCheckBox(newsletterCheckBox ? false : true);
                  setValue('newsletter', false);
                }}
                control={<Checkbox checked={newsletterCheckBox} />}
                label={`Desejo ${
                  defaultValues.newsletter
                    ? `cancelar minha assinatura da Newsletter para não receber notícias e ofertas através de e-mail, SMS e Whastapp.`
                    : `assinar a Newsletter para receber notícias e ofertas através de e-mail, SMS e Whastapp.`
                }`}
              />
            </div>

            <Divider sx={{ margin: '20px 0' }} />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Salvar
            </LoadingButton>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

