import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { GoQuestion } from 'react-icons/go';
import { ModalDuvidas } from 'src/pages/auth/ModalDuvidas';

import Logo from '../Logo';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));
const BoxQuestion = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '13px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export default function SimpleHeader() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <HeaderStyle>
      <Logo />
      <BoxQuestion onClick={() => handleClickOpen()}>
        <GoQuestion style={{ marginRight: '5px' }} />
        Dúvidas
      </BoxQuestion>
      <ModalDuvidas open={open} onClose={handleClose} />
    </HeaderStyle>
  );
}
